import React from 'react';
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { FiPhone } from 'react-icons/fi';
import PropTypes from 'prop-types';
import sanitizeHTML from 'sanitize-html';
import '../styles/components/DirectoryItem.scss';
import { useTranslation } from 'react-i18next';
import formatPhoneNumber from '../helpers/phone';

const DirectoryItemMulti = ({
  header, phone, check, setCheck, isArrow, handleCard,
}) => {
  const { t } = useTranslation();
  return (
    <div className="directory-item">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setCheck();
        }}
        className="check-button"
      >
        <div className="left-side">
          <div className="check-icon-container">
            {check ? <FaRegCheckCircle title={t('selected')} /> : <FaRegCircle title={t('notSelected')} />}
          </div>
          <h3>{header}</h3>
        </div>
      </button>
      <div className="right-side">
        {phone !== '' && (
        <a href={sanitizeHTML(`tel:${phone}}`)}>
          <div className="phone">
            <h3>
              {formatPhoneNumber(phone)}
            </h3>
            <FiPhone title={t('callNow')} />
          </div>
        </a>
        )}
        {isArrow && (
        <button type="button" onClick={() => handleCard()}>
          <IoIosArrowForward title={t('goToContact')} />
        </button>
        )}
      </div>
    </div>
  );
};

DirectoryItemMulti.defaultProps = {
  header: '',
  phone: '',
  check: false,
  setCheck: () => { },
  isArrow: false,
  handleCard: () => { },
};

DirectoryItemMulti.propTypes = {
  header: PropTypes.string,
  phone: PropTypes.string,
  check: PropTypes.bool,
  setCheck: PropTypes.func,
  isArrow: PropTypes.bool,
  handleCard: PropTypes.func,
};

export const DirectoryItemSingle = ({ header, onClick }) => (
  <div className="directory-item">
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="directory-single-button"
    >
      <h3>{header}</h3>
    </button>
  </div>
);
DirectoryItemSingle.defaultProps = {
  header: '',
  onClick: () => { },
};

DirectoryItemSingle.propTypes = {
  header: PropTypes.string,
  onClick: PropTypes.func,
};

export default DirectoryItemMulti;
