import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isFrenchSelected } from '../hooks/useTranslation';
import KDCLogo from './KDCLogo';

// Cooperators Icon
import CoopLogoImage from '../assets/images/Cooperators_Logo_Image.png';
import CoopLogoImageWhite from '../assets/images/Cooperators_Logo_Image_White.png';

// Cooperators Logo With Motto
import CoopMotto from '../assets/images/Cooperators_EN_Logo_Tagline.png';
import CoopMottoFr from '../assets/images/Cooperators_FR_Logo_Tagline.png';

// Large KDC Logo
import KDCLogoImage from '../assets/images/KDC_Logo_New_Blue.svg';
import KDCLogoFrImage from '../assets/images/KDC_Logo_New_Blue_French.svg';

// Single Line White KDC Logo
import KDCSmallWhiteLogo from '../assets/images/KDC_Logo_New_Small.svg';
import KDCSmallWhiteLogoFr from '../assets/images/KDC_Logo_New_Small_French.svg';

// White Cooperators Logo
import white from '../assets/images/Cooperators_Logo_New_White.png';
import whiteFr from '../assets/images/Cooperators_Logo_New_White_French.png';

// Standard Cooperators Logo
import logo from '../assets/images/Cooperators_Logo_New.png';
import logoFr from '../assets/images/Cooperators_Logo_New_French.png';

import '../styles/components/Logo.scss';

/**
 * Logo Component that auto handles switching logo based on locale
 * @returns {React.ReactNode}
 */
const Logo = ({ type }) => {
  const { t, i18n } = useTranslation();
  const isFrench = isFrenchSelected(i18n);

  return (
    <>
      { // Co-operators Banner (logo and text) that is in image form.
        type === 'CoopBanner' && (
          <img
            className="coop-logo"
            alt={t('theCooperators')}
            src={isFrench ? logoFr : logo}
          />
        )
      }

      { // Co-operators Logo (no text) that is in image form.
        type === 'CoopLogo' && (
          <img
            className="coop-logo-icon"
            alt={t('theCooperatorsLogo')}
            src={CoopLogoImage}
          />
        )
      }

      { // White Co-operators Logo (no text) that is in image form.
        type === 'CoopLogoWhite' && (
          <img
            className="coop-logo-icon"
            alt={t('theCooperatorsLogo')}
            src={CoopLogoImageWhite}
          />
        )
      }

      { // Co-operators Banner (logo and text) with motto, that is in image form.
        type === 'CoopBannerMotto' && (
          <img
            className="coop-logo"
            alt={t('cooperatorsMotto')}
            src={isFrench ? CoopMottoFr : CoopMotto}
          />
        )
      }

      { // White Co-operators Banner (logo and text) that is in image form.
        type === 'CoopBannerWhite' && (
          <img
            className="coop-logo"
            alt={t('theCooperators')}
            src={isFrench ? whiteFr : white}
          />
        )
      }

      { // KDC Banner (logo and text) that is in image form.
        type === 'KDCBannerImage' && (
          <img
            className="coop-logo"
            alt={t('kdcFullName')}
            src={isFrench ? KDCLogoFrImage : KDCLogoImage}
          />
        )
      }

      { // In-line White KDC Banner (logo and text). Does not spin.
        type === 'KDCBannerInlineWhite' && (
          <img
            className="coop-logo coop-logo-inline"
            alt={t('kdcFullName')}
            src={isFrench ? KDCSmallWhiteLogoFr : KDCSmallWhiteLogo}
          />
        )
      }

      { // KDC Logo (no text) that spins.
        type === 'KDCSpinningLogo' && (
        <KDCLogo spinning />
        )
      }

      { // KDC Logo (no text) that does not spin.
        type === 'KDCLogo' && (
        <KDCLogo spinning={false} />
        )
      }
    </>
  );
};

Logo.defaultProps = {
  type: 'CoopBanner',
};

Logo.propTypes = {
  type: PropTypes.oneOf([
    'CoopBanner',
    'CoopLogo',
    'CoopLogoWhite',
    'CoopBannerMotto',
    'CoopBannerWhite',
    'KDCBannerImage',
    'KDCBannerInlineWhite',
    'KDCSpinningLogo',
    'KDCLogo',
  ]),
};

export default Logo;
