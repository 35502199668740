import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/pages/StartEventPage.scss';
import { FaXmark } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import {
  useBlocker, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StyledSelect from '../StyledSelect';
import SearchBar from '../SearchBar';
// release 2 functionality
// import ToggleSwitch from '../ToggleSwitch';
import CircledButton from '../CircledButton';
import axios from '../../services/Api';
import { getDirectoryRest } from '../../hooks/DirectoryHook';
import MultiSelectDropDownStartEvent from '../MultiSelectDropDownStartEvent';
import DirectoryItem from '../DirectoryItem';
import TwoButtonModal from '../TwoButtonModal';
import EventTemplateService from '../../services/EventTemplateService';
import Card from '../Card';
import BlueButton from '../BlueButton';

const StartEventPage = ({ addUpdate }) => {
  const history = useNavigate();
  const eventTemplate = EventTemplateService();
  const selectTypeOptions = [{
    value: JSON.stringify(eventTemplate.custom),
    label: eventTemplate.custom.display,
  }, {
    value: JSON.stringify(eventTemplate.fire),
    label: eventTemplate.fire.display,
  }, {
    value: JSON.stringify(eventTemplate.powerOutage),
    label: eventTemplate.powerOutage.display,
  }];
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id } = useParams();
  const [type, setType] = useState(selectTypeOptions[0]);
  const [eventMain, setEventMain] = useState({
    summary: '',
    payload: {
      notification: {
        title: '',
        body: '',
      },
      data: {
        status: '',
        nextSteps: '',
        question: {
          questionDetails: '',
          yes: [],
          no: [],
        },
      },
    },
    addTeamsLink: true,
    emailsSms: false,
  });

  const [alert, setAlert] = useState({
    notification: {
      title: '',
      body: '',
    },
    data: {
      status: '',
      nextSteps: '',
      question: {
        questionDetails: '',
        yes: [],
        no: [],
      },
    },
  });
  const [recipientSelectError, setRecipientSelectedError] = useState('');
  const [eventMainError, setEventMainError] = useState({
    summary: '',
    description: '',
  });
  const [recipients, setRecipients] = useState({});
  const [locations, setLocations] = useState([]);
  const [affectedLocations, setAffectedLocations] = useState([]);
  const [selectAllRole, setSelectAllRole] = useState({});
  const [selectAllNone, setSelectAllNone] = useState(false);
  const [eventSubmitted, setEventSubmitted] = useState(false);
  const handleSelectAllNone = (
    recipientsOverwrite, selectRolesOverwrite, selectAllNoneOverwrite, selected,
  ) => {
    const recipientsTemp = JSON.parse(JSON.stringify(recipientsOverwrite || recipients));
    const roleTemp = JSON.parse(JSON.stringify(selectRolesOverwrite || selectAllRole));
    Object.keys(recipientsTemp).forEach((key) => {
      for (let i = 0; i < recipientsTemp[key].length; i += 1) {
        const recipient = recipientsTemp[key][i];
        recipient.select = selected !== undefined ? selected : !selectAllNone;
      }
      if (roleTemp[key]) {
        roleTemp[key].selectAll = selected !== undefined ? selected : !selectAllNone;
      }
    });
    setSelectAllNone(selectAllNoneOverwrite
      !== undefined ? selectAllNoneOverwrite : !selectAllNone);
    setSelectAllRole(roleTemp);
    setRecipients(recipientsTemp);
  };
  const handleChange = (newValue, setData, data, branch) => {
    const dataTemp = JSON.parse(JSON.stringify(data));
    if (branch[1]) {
      if (branch[3]) {
        dataTemp[branch[0]][branch[1]][branch[2]][branch[3]] = newValue;
      } else if (branch[2]) {
        dataTemp[branch[0]][branch[1]][branch[2]] = newValue;
      } else {
        dataTemp[branch[0]][branch[1]] = newValue;
      }
    } else {
      dataTemp[branch[0]] = newValue;
    }
    setData(dataTemp);
  };
  const handleAffectedLocations = async (addedLocation) => {
    const recipientsTemp = affectedLocations.length === 0
      ? {} : JSON.parse(JSON.stringify(recipients));
    const roleTemp = JSON.parse(JSON.stringify(selectAllRole));
    if (addedLocation === '') {
      const data = await getDirectoryRest(0, [], [], '', true);
      data.forEach((person) => {
        person?.role.forEach((roleItem) => {
          if (recipientsTemp[roleItem.name]) {
            recipientsTemp[roleItem.name] = [...recipientsTemp[roleItem.name], {
              name: person.fullName,
              location: person.location !== null ? person.location : '',
              _id: person._id,
              select: false,
              email: person.email,
            }];
          } else {
            recipientsTemp[roleItem.name] = [{
              name: person.fullName,
              location: person.location !== null ? person.location : '',
              _id: person._id,
              select: false,
              email: person.email,
            }];
            roleTemp[roleItem.name] = { selectAll: false, isExpanded: false };
          }
        });
      });
      setSelectAllRole(roleTemp);
      setRecipients(recipientsTemp);
      handleSelectAllNone(recipientsTemp, roleTemp, false, false, false);
    } else if (!affectedLocations.includes(addedLocation.value)) {
      setAffectedLocations([...affectedLocations, addedLocation.value]);
      const data = await getDirectoryRest(0, [], [addedLocation.value], '', true);
      data.forEach((person) => {
        person?.role.forEach((roleItem) => {
          if (roleItem?.locations.find(
            (addedLocationItem) => addedLocationItem?.location === addedLocation.value,
          )) {
            if (recipientsTemp[roleItem.name]) {
              recipientsTemp[roleItem.name] = [...recipientsTemp[roleItem.name], {
                name: person.fullName,
                location: addedLocation.value,
                _id: person._id,
                select: false,
                email: person.email,
              }];
            } else {
              recipientsTemp[roleItem.name] = [{
                name: person.fullName,
                location: addedLocation.value,
                _id: person._id,
                select: false,
                email: person.email,
              }];
              roleTemp[roleItem.name] = { selectAll: false, isExpanded: false };
            }
          }
        });
      });
      setSelectAllRole(roleTemp);
      setRecipients(recipientsTemp);
      handleSelectAllNone(recipientsTemp, roleTemp, false, false, false);
    }
  };
  useEffect(() => {
    if (affectedLocations.length === 0) {
      handleAffectedLocations('');
    }
  }, [affectedLocations]);
  const handleExpandAll = (key) => {
    const roleTemp = JSON.parse(JSON.stringify(selectAllRole));
    roleTemp[key].isExpanded = !roleTemp[key].isExpanded;
    setSelectAllRole(roleTemp);
  };
  const handleSelectAllRecipients = (key) => {
    const recipientsTemp = JSON.parse(JSON.stringify(recipients));
    const roleTemp = JSON.parse(JSON.stringify(selectAllRole));
    for (let i = 0; i < recipientsTemp[key].length; i += 1) {
      const recipient = recipientsTemp[key][i];
      recipient.select = !roleTemp[key].selectAll;
    }
    roleTemp[key].selectAll = !roleTemp[key].selectAll;
    setSelectAllRole(roleTemp);
    setRecipients(recipientsTemp);
  };
  const handleSelectRecipient = (key, identifier) => {
    const recipientsTemp = JSON.parse(JSON.stringify(recipients));
    for (let i = 0; i < recipientsTemp[key].length; i += 1) {
      const recipient = recipientsTemp[key][i];
      if (recipient._id === identifier) {
        recipient.select = !recipient.select;
      }
    }
    setRecipients(recipientsTemp);
  };
  const handleRemoveLocation = (index, location) => {
    setAffectedLocations(affectedLocations.filter((_, i) => i !== index));
    const recipientsTemp = JSON.parse(JSON.stringify(recipients));
    const roleTemp = JSON.parse(JSON.stringify(selectAllRole));
    Object.keys(recipientsTemp).forEach((key) => {
      recipientsTemp[key] = recipientsTemp[key]
        .filter((recipient) => recipient.location !== location);
      if (recipientsTemp[key].length === 0) {
        delete recipientsTemp[key];
        delete roleTemp[key];
      }
    });
    setSelectAllRole(roleTemp);
    setRecipients(recipientsTemp);
  };
  const fetchLocations = async () => {
    const { data } = await axios.get('/constants/locations');
    setLocations(data);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  /**
   * Re-usable function to get recipients of an alert
   * @returns
   */
  const getFinalRecipients = () => {
    let recipientsFinal = [];
    Object.keys(recipients).forEach((key) => {
      const formatted = recipients[key].filter(
        (recipient) => recipient.select === true,
      )
        .map((recipient) => ({
          id: recipient._id,
          name: recipient.name,
          email: recipient.email,
        }));
      recipientsFinal = [...recipientsFinal, ...formatted];
    });
    const uniqueRecipients = recipientsFinal.filter(
      (value, index, self) => index === self.findIndex((recipient) => (
        recipient.id === value.id && recipient.name === value.name
      )),
    );
    return uniqueRecipients;
  };

  /**
   * Submit new event
   */
  const submitEvent = async () => {
    let err = false;
    const eventMainErrorTemp = JSON.parse(JSON.stringify(eventMainError));
    try {
      if (eventMain.summary.length === 0) {
        err = true;
        eventMainErrorTemp.summary = t('requiredError', { header: t('summary') });
      } else {
        eventMainErrorTemp.summary = '';
      }
      if (eventMain.payload.data.status.length === 0) {
        err = true;
        eventMainErrorTemp.description = t('requiredError', { header: t('description') });
      } else {
        eventMainErrorTemp.description = '';
      }

      const uniqueRecipients = getFinalRecipients();

      if (uniqueRecipients.length === 0) {
        setRecipientSelectedError(t('selectRecipientError'));
        err = true;
      } else {
        setRecipientSelectedError('');
      }
      if (err) {
        setEventMainError(eventMainErrorTemp);
        throw new Error();
      }
      const final = {
        locations: affectedLocations.length === 0
          ? locations.map((location) => location.name) : affectedLocations,
        summary: eventMain.summary,
        alerts: [{
          payload: { ...eventMain.payload },
          recipients: uniqueRecipients,
        }],
      };
      await axios.post('/event/start', final);
      toast(t('eventUploadSuccess'), { type: 'success' });
      setEventMain(eventTemplate.custom);
      setAffectedLocations([]);
      setRecipients({});
      setSelectAllRole({});
      setEventMainError({});
      setSelectAllNone(false);
      setType(selectTypeOptions[0]);
    } catch (error) {
      toast(t('eventUploadError'), { type: 'error' });
    }
  };
  const isEdited = () => {
    let edited = false;
    if (eventMain.summary !== ''
      || eventMain.payload.data.status !== ''
      || eventMain.payload.data.nextSteps !== ''
      || eventMain.payload.data.question.questionDetails !== ''
      || affectedLocations.length !== 0) {
      edited = true;
    }
    if (alert.data.status !== ''
      || alert.data.nextSteps !== ''
      || alert.data.question.questionDetails !== '') {
      edited = true;
    }
    let recipientsFinal = [];
    Object.keys(recipients).forEach((key) => {
      const formatted = recipients[key].filter((recipient) => recipient.select === true)
        .map((recipient) => ({
          id: recipient._id,
          name: recipient.name,
        }));
      recipientsFinal = [...recipientsFinal, ...formatted];
    });
    if (recipientsFinal.length > 0) {
      edited = true;
    }
    return (edited);
  };
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname && isEdited() && !eventSubmitted
    ),
  );

  /**
   * Update existing event
   */
  const updateEvent = async () => {
    let err = false;
    const alertErrorTemp = JSON.parse(JSON.stringify(eventMainError));
    try {
      if (alert.data.status.length === 0) {
        err = true;
        alertErrorTemp.description = t('requiredError', { header: t('description') });
      } else {
        alertErrorTemp.description = '';
      }

      const uniqueRecipients = getFinalRecipients();

      if (uniqueRecipients.length === 0) {
        setRecipientSelectedError(t('selectRecipientError'));
        err = true;
      } else {
        setRecipientSelectedError('');
      }
      if (err) {
        setEventMainError(alertErrorTemp);
        throw new Error();
      }

      const finalAlert = { payload: alert, recipients: getFinalRecipients() };
      const toastOptions = {
        pending: t('savingAlert'),
        success: t('eventUpdateSuccess'),
        error: t('eventUpdateError'),
      };
      await toast.promise(axios.post(`/event/${id}`, finalAlert), toastOptions);
      setAffectedLocations([]);
      setRecipients({});
      setSelectAllRole({});
      setEventMainError({});
      setSelectAllNone(false);
      setType(selectTypeOptions[0]);
      setEventSubmitted(true);
    } catch (error) {
      // only show this error on user error
      if (err) {
        toast(t('alertUpdateError'), { type: 'error' });
      }
    }
  };

  useEffect(() => {
    if (eventSubmitted) {
      history(-1);
      window.scrollTo(0, 0);
    }
  }, [eventSubmitted]);

  useEffect(() => {
    // for loading to the top of screen when editing
    window.scrollTo(0, 0);

    if (!addUpdate) {
      const unloadCallback = (e) => {
        e.preventDefault();
        return '';
      };
      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
    return () => {};
  }, []);

  return (
    <Card
      header={addUpdate ? `${t('update')}: ${state.summary}` : t('event')}
      functionalButton={addUpdate
        ? (
          <CircledButton onClick={() => history('/events')}>
            <FaXmark title={t('cancelChanges')} />

          </CircledButton>
        ) : undefined}
    >

      <div className="start-event">
        <div className="event-form">
          {!addUpdate && (
          <>
            <div className="start-event-template">
              <h2>
                {`${t('template')}: `}
              </h2>
              <div className="start-event-select-row">
                <StyledSelect
                  options={selectTypeOptions}
                  defaultValue={{
                    value: JSON.stringify(eventTemplate.custom),
                    label: eventTemplate.custom.display,
                  }}
                  onChange={(change) => {
                    setEventMain({ ...JSON.parse(change.value), addTeamsLink: true });
                    setType(change);
                  }}
                  value={type}
                />
                <span className="start-event-select-row-support-text small-text">
                  {t('supportText')}
                </span>
              </div>
            </div>
            <SearchBar
              placeholder={`${t('summary')} *`}
              dynamicHeader={`${t('summary')} *`}
              isSearchIcon={false}
              type="string"
              label={t('summary')}
              value={eventMain.summary}
              onChange={(e) => {
                handleChange(e, setEventMain, eventMain, ['summary']);
              }}
              id="startEventSummary"
            />
            {eventMainError.summary && <p className="error">{eventMainError.summary}</p>}
          </>
          )}
          <SearchBar
            placeholder={`${t('description')} *`}
            dynamicHeader={`${t('description')} *`}
            isSearchIcon={false}
            type="text"
            label={t('description')}
            value={addUpdate ? alert.data.status : eventMain.payload.data.status}
            isTextArea
            onChange={(e) => (addUpdate ? handleChange(e, setAlert, alert, ['data', 'status']) : handleChange(e, setEventMain, eventMain, ['payload', 'data', 'status']))}
            id="startEventDescription"
          />

          {eventMainError.description && <p className="error">{eventMainError.description}</p>}
          <SearchBar placeholder={t('nextSteps')} dynamicHeader={t('nextSteps')} isSearchIcon={false} type="text" label={t('nextSteps')} value={addUpdate ? alert.data.nextSteps : eventMain.payload.data.nextSteps} isTextArea onChange={(e) => (addUpdate ? handleChange(e, setAlert, alert, ['data', 'nextSteps']) : handleChange(e, setEventMain, eventMain, ['payload', 'data', 'nextSteps']))} id="startEventNextSteps" />

          <div className="start-event-switches">
            {/** Do not show switches for non-existing functionality */}
            {/* <div className="start-event-switches-rows">
              <span>{t('addTeamsMeetLink')}</span>
              <ToggleSwitch id="addTeamsLink" name="add-teams-link" value={eventMain.addTeamsLink}
                onChange={() => {
                  handleChange(!eventMain.addTeamsLink, setEventMain, eventMain, ['addTeamsLink']);
                }} />
            </div> */}
            {/* <div className="start-event-switches-rows">
              <span>{t('sendEmailSMS')}</span>
              <ToggleSwitch id="emailSms" name="emailSms" value={eventMain.emailsSms}
                onChange={() => {
                  handleChange(!eventMain.emailsSms, setEventMain, eventMain, ['emailsSms']);
                }} />
            </div> */}
            <span className="small-text">
              {t('defaultCorpEmail')}
            </span>
            <span className="small-text">
              {t('requiredFields')}
            </span>
          </div>
        </div>
        {!addUpdate && (
        <div>
          <h2 className="start-event-header">
            {t('affectedLocations')}
          </h2>
          <div className="event-form">
            {locations.length > 0 && (
            <StyledSelect
              options={locations
                .map((location) => ({ value: location.name, label: location.name }))}
              value={{ label: t('selectLocation'), value: '' }}
              onChange={handleAffectedLocations}
            />
            )}
            <div className="selected-locations">
              {affectedLocations.map((location, index) => (
                <div className="selected-location" key={location}>
                  <span>
                    {location}
                  </span>
                  <CircledButton
                    onClick={
                    () => handleRemoveLocation(index, affectedLocations[index])
                  }
                    label={t('removeLocation')}
                  >
                    <FaXmark />
                  </CircledButton>
                </div>
              ))}
              <span className="keep-empty small-text">
                {t('keepEmptyToTargetAllLocations')}
              </span>
            </div>
          </div>
        </div>
        )}
        <h2 className="start-event-header">
          {t('recipients')}
        </h2>
        <div className="event-form">
          {Object.keys(recipients).length > 0 && (
            <>
              <div className="select-all-none">
                <DirectoryItem header={t('selectAllNone')} check={selectAllNone} setCheck={() => handleSelectAllNone()} />
              </div>
              {Object.keys(recipients).sort().map((key) => (
                <MultiSelectDropDownStartEvent
                  header={key}
                  items={recipients[key]}
                  key={key}
                  handleSelect={handleSelectRecipient}
                  isAllSelected={selectAllRole[key]}
                  handleSelectAll={handleSelectAllRecipients}
                  invertExpanded={handleExpandAll}
                />
              ))}
              {recipientSelectError.length > 0 && <p className="error">{recipientSelectError}</p>}
              <div className="start-event-switches">
                <span className="small-text">
                  {t('oneRecipientRequired')}
                </span>
              </div>
            </>
          )}
          {Object.keys(recipients).length === 0 && (
            <p>{t('locationRecipientsError')}</p>
          )}
        </div>
        {/* Unreleased functionality
        <h2 className="start-event-header">
          {addUpdate ? `${t('update')} ${t('eventYesNoSurvey')}` : t('eventYesNoSurvey')}
        </h2> */}
        <div className="event-form">
          {/* Unreleased functionality
          <SearchBar
            placeholder={t('yesNoQuestionToAsk')}
            id="yesNoCustomQuestion"
            dynamicHeader={t('yesNoQuestionToAsk')}
            isSearchIcon={false}
            type="text"
            label={t('yesNoQuestionToAsk')}
            value={addUpdate
              ? alert.data.question.questionDetails
              : eventMain.payload.data.question.questionDetails}
            onChange={(e) => (addUpdate ?
              handleChange(e, setAlert, alert, ['data', 'question', 'questionDetails'])
              : handleChange(e, setEventMain, eventMain,
                ['payload', 'data', 'question', 'questionDetails']))}
          /> */}
          <div className="submit-button-container">
            <BlueButton onClick={() => (addUpdate ? updateEvent() : submitEvent())} text={addUpdate ? t('updateEventAlert') : t('sendEventAlert')} />
          </div>
        </div>
        {blocker.state === 'blocked' && (
        <TwoButtonModal
          isOpen
          redButtonFunctionality={() => blocker.proceed()}
          blueButtonFunctionality={() => blocker.reset()}
          closeFunctionality={() => blocker.reset()}
          headerText={t('moveAwayModalHeader')}
          redButtonText={t('leave')}
          blueButtonText={t('stay')}
        />
        )}
      </div>
    </Card>
  );
};

StartEventPage.defaultProps = {
  addUpdate: false,
};

StartEventPage.propTypes = {
  addUpdate: PropTypes.bool,
};

export default StartEventPage;
