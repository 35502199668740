import React from 'react';
import PropTypes from 'prop-types';
import IconColours from './IconColours';

/** Decision Makers Icon */
const DecisionMakersIcon = ({
  width, height, title, className, color,
}) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <title>{title}</title>
    <path
      d="M6.13339 54.0799L31.7334 28.4799L28.0001 24.6932L26.0801 26.5866C25.8334 26.8338 25.5403 27.0299 25.2177 27.1637C24.8951 27.2975 24.5493 27.3664 24.2001 27.3664C23.8508 27.3664 23.505 27.2975 23.1824 27.1637C22.8598 27.0299 22.5668 26.8338 22.3201 26.5866L20.4267 24.6932C20.1795 24.4465 19.9834 24.1535 19.8496 23.8309C19.7158 23.5083 19.6469 23.1625 19.6469 22.8132C19.6469 22.464 19.7158 22.1182 19.8496 21.7956C19.9834 21.473 20.1795 21.1799 20.4267 20.9332L35.5201 5.83991C35.7668 5.5927 36.0598 5.39657 36.3824 5.26276C36.705 5.12894 37.0508 5.06006 37.4001 5.06006C37.7493 5.06006 38.0951 5.12894 38.4177 5.26276C38.7403 5.39657 39.0334 5.5927 39.2801 5.83991L41.1734 7.73324C42.2134 8.77324 42.2134 10.4532 41.1734 11.4932L39.2801 13.3332L43.0667 17.1466C43.3134 16.8994 43.6065 16.7032 43.9291 16.5694C44.2517 16.4356 44.5975 16.3667 44.9467 16.3667C45.296 16.3667 45.6418 16.4356 45.9644 16.5694C46.287 16.7032 46.58 16.8994 46.8267 17.1466C47.8667 18.1866 47.8667 19.8932 46.8267 20.9332L50.5867 24.6932L52.4801 22.7999C53.5201 21.7599 55.2267 21.7599 56.2667 22.7999L58.1334 24.6932C59.1734 25.7332 59.1734 27.4399 58.1334 28.4799L43.0667 43.5466C42.0267 44.5866 40.3201 44.5866 39.2801 43.5466L37.4134 41.6799C37.1602 41.4339 36.9589 41.1395 36.8215 40.8143C36.684 40.4891 36.6132 40.1396 36.6132 39.7866C36.6132 39.4335 36.684 39.084 36.8215 38.7588C36.9589 38.4336 37.1602 38.1393 37.4134 37.8932L39.2801 35.9999L35.5201 32.2399L9.89339 57.8666C9.64669 58.1138 9.35365 58.3099 9.03106 58.4437C8.70846 58.5775 8.36264 58.6464 8.01339 58.6464C7.66414 58.6464 7.31832 58.5775 6.99573 58.4437C6.67313 58.3099 6.3801 58.1138 6.13339 57.8666C5.09339 56.8266 5.09339 55.1199 6.13339 54.0799ZM53.3334 50.6666C54.7479 50.6666 56.1044 51.2285 57.1046 52.2287C58.1048 53.2289 58.6667 54.5854 58.6667 55.9999V58.6666H32.0001V55.9999C32.0001 54.5854 32.562 53.2289 33.5622 52.2287C34.5624 51.2285 35.9189 50.6666 37.3334 50.6666H53.3334Z"
      fill={color}
    />
  </svg>
);

DecisionMakersIcon.defaultProps = {
  width: 64,
  height: 64,
  title: '',
  className: undefined,
  color: IconColours.BLUE,
};

DecisionMakersIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default DecisionMakersIcon;
