import axios from 'axios';
import msalInstance from './AuthService';
import ApiConfig from '../config/ApiConfig';

/** Before request sends event name */
export const requestStartEventName = 'axios:requestStart';
/** After a request receives a response event name */
export const requestEndEventName = 'axios:requestEnd';
/** A DOM event that can be dispatched */
export const requestStartEvent = new Event(requestStartEventName);
/** A DOM event that can be dispatched */
export const requestEndEvent = new Event(requestEndEventName);

/**
 *  Creates an axios instance with the baseURL set to the backend,
 *  and potentially more configuration.
 */
const instance = axios.create(ApiConfig);

/**
 * Redirect callback used in logoutRedirect to perform local logout
 * Returns false to prevent users being redirected to the server logout page
 */
export const logoutRedirectCallback = () => false;

/** Ensures current authorization token is included with every request */
export const requestInterceptor = async (config) => {
  let authConfig = config;
  const account = msalInstance.getActiveAccount();

  if (account) {
    const token = account.idToken;
    authConfig = {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  window.dispatchEvent(requestStartEvent);
  return authConfig;
};

/** request error handler */
export const requestErrorHandler = (error) => {
  window.dispatchEvent(requestEndEvent);
  return Promise.reject(error);
};

/** 401 handler */
export const errorHandler = async (error) => {
  if (error.message.includes('401')) {
    try {
      await msalInstance.acquireTokenSilent({});
    } catch {
      window.dispatchEvent(requestEndEvent);
      return msalInstance.logoutRedirect({ onRedirectNavigate: logoutRedirectCallback });
    }
    const account = msalInstance.getActiveAccount();
    try {
      let retryConfig = error.config;
      if (account) {
        const token = account.idToken;
        retryConfig = {
          ...retryConfig,
          headers: {
            ...retryConfig.headers,
            authorization: `Bearer ${token}`,
          },
        };
      }
      const result = await axios.request(retryConfig);
      window.dispatchEvent(requestEndEvent);
      return result;
    } catch (err) {
      // If we fail twice just log user out
      if (err.message.includes('401')) {
        window.dispatchEvent(requestEndEvent);
        return msalInstance.logoutRedirect({ postLogoutRedirectUri: window.AZURE_REDIRECT_URL });
      }
    }
  }
  window.dispatchEvent(requestEndEvent);

  return Promise.reject(error);
};

/** Normal response handler */
export const responseHandler = (response) => {
  window.dispatchEvent(requestEndEvent);
  return response;
};

instance.interceptors.request.use(requestInterceptor, requestErrorHandler);
instance.interceptors.response.use(responseHandler, errorHandler);

export default instance;
