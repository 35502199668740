import axios from '../services/Api';

/**
 * gets events created by a user
*/
export const getActiveEvents = async (signal) => {
  const { data } = await axios.get('/event/active', { signal });
  return (data);
};
/**
 * gets events assigned to a user
*/
export const getCurrentEvents = async (signal) => {
  const { data } = await axios.get('/current-events', { signal });
  return (data);
};
