import React from 'react';
import Modal from 'react-modal';
import { createRoot } from 'react-dom/client';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider,
} from '@azure/msal-react';
import { CacheLookupPolicy, InteractionRequiredAuthError } from '@azure/msal-browser';
import { initializeMSAL } from './services/AuthService';
import AppRouter from './routers/AppRouter';
import LoginPage from './components/pages/LoginPage';
import initializeI18n from './i18n';
import packageJson from '../package.json';
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import UserProvider from './providers/UserProvider';

const root = createRoot(document.getElementById('app'));

// changes all other components to aria-hidden while modal is open
// deals with accessibility error from modal for screen readers
// could be replaced with ariaHideApp={false} in Modal props
Modal.setAppElement(document.getElementById('app'));

/**
 * Initializes application
 * based on MSAL
 */
const initializeApp = async () => {
  initializeI18n();
  global.appVersion = packageJson.version;
  const msalInstance = await initializeMSAL();

  // Attempt to refresh the token if we were logged in
  if (msalInstance.getActiveAccount()) {
    try {
      await msalInstance.acquireTokenSilent({
        cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
      });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await msalInstance.logoutRedirect({
          onRedirectNavigate: () => (
            // Return false to prevent server logout page
            // This forces the login page to display if we're not able to refresh the token
            false),
        });
      } else {
        throw e;
      }
    }
  }

  root.render(
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <UserProvider>
          <AppRouter />
        </UserProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </MsalProvider>,
  );
};

initializeApp();
