import React from 'react';
import PropTypes from 'prop-types';

import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { useTranslation } from 'react-i18next';

import '../styles/components/Login.scss';
/**
 * Component for the KDC Login page
 * @param {object} props The properties passed into the component
 */
const Login = ({
  logoutProp, title, logoutBtnContent,
}) => {
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();

  /** Initiates the login */
  const login = () => {
    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect();
    }
  };
  /** Initiates the logout */
  const logout = () => {
    if (inProgress === InteractionStatus.None) {
      instance.logoutRedirect();
    }
  };

  if (!logoutProp) {
    return (
      <div className="login">
        <div className="loginBar">
          <p className="loginText">{title}</p>
        </div>
        <div className="loginHr">
          <p>{t('azureADLogin')}</p>
          <button type="button" className="loginBtn" onClick={login}>
            {t('azureLogin')}
          </button>
        </div>
      </div>
    );
  }
  return (
    <button className="logoutBtn" type="button" onClick={logout} name={t('logout')} aria-label={t('logout')}>
      <h2>{t('logoutNoun')}</h2>
      {logoutBtnContent()}
    </button>
  );
};
export default Login;

Login.defaultProps = {
  logoutProp: false,
  title: '',
  logoutBtnContent: () => { },
};

Login.propTypes = {
  logoutProp: PropTypes.bool,
  title: PropTypes.string,
  logoutBtnContent: PropTypes.func,
};
