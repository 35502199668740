import React from 'react';
import { useTranslation } from 'react-i18next';

// styles and assets
import '../styles/components/Footer.scss';
import Logo from './Logo';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="logo">
        <h4 className="tagline">
          {t('poweredBy')}
        </h4>
        <Logo type="KDCBannerInlineWhite" />
      </div>
    </footer>
  );
};

export default Footer;
