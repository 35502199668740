import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/Card.scss';

/**
 * Card component
 * @param {header} element
 * @param {children} element
 * @returns {JSX} JSX to render the component
 */

const Card = ({
  header, children, className, functionalButton, isInternal,
}) => (
  <div className={isInternal ? 'internal-card' : `initial-card ${className}`}>
    {header && (
    <div className="card-header">
      {header}
      {functionalButton && <div className="functional-button">{functionalButton}</div>}
    </div>
    )}
    <div>
      {children}
    </div>
  </div>
);

Card.defaultProps = {
  functionalButton: null,
  className: '',
  header: undefined,
  isInternal: false,
};

Card.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  functionalButton: PropTypes.node,
  isInternal: PropTypes.bool,
};

export default Card;
