import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPhone } from 'react-icons/fi';
import { SiGooglemaps } from 'react-icons/si';
import sanitizeHTML from 'sanitize-html';
import { toast } from 'react-toastify';
import StyledSelect from '../StyledSelect';
import axios from '../../services/Api';
import Card from '../Card';
import CollapsibleRow from '../CollapsibleRow';
import BlueButton from '../BlueButton';
import formatPhoneNumber from '../../helpers/phone';

import '../../styles/components/pages/CommandCentersPage.scss';

const CommandCentersPage = () => {
  const { t } = useTranslation();

  const [locationNames, setLocationNames] = useState([]);

  const [selectedLocationName, setSelectedLocationName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [expandedRows, setExpandedRows] = useState({});

  // Get Locations
  useEffect(() => {
    const abortController = new AbortController();
    const getLocationNames = async () => {
      try {
        const { data } = await axios.get('/user/locations', { signal: abortController.signal });
        setLocationNames(data);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
    };
    getLocationNames();
    return () => {
      abortController.abort();
    };
  }, []);

  // Get Location data (command centers) for Location Name
  useEffect(() => {
    const abortController = new AbortController();
    const getLocation = async () => {
      try {
        const { data } = await axios.get(`/constants/locations/${selectedLocationName}`, { signal: abortController.signal });
        setSelectedLocation(data);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingCommandCenters'));
      }
    };

    if (selectedLocationName) {
      getLocation();
    }

    return () => {
      abortController.abort();
    };
  }, [selectedLocationName]);

  const handleLocationChange = (newLocationName) => {
    if (newLocationName.label === selectedLocationName) {
      return;
    }
    setExpandedRows({});
    setSelectedLocationName(newLocationName.label);
  };

  const handleRowExpandedChange = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const oneExpanded = () => {
    const expansions = Object.values(expandedRows);

    if (expansions.length === 0) {
      return false;
    }

    return expansions.some((expansion) => expansion === true);
  };

  const invertAllExpanded = () => {
    const isOneExpanded = oneExpanded();
    const newExpandedRows = { ...expandedRows };

    selectedLocation.commandCenters.forEach((_, index) => {
      newExpandedRows[index] = !isOneExpanded;
    });
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className="command-centers-page">
      <Card header={<div>{t('locations')}</div>}>
        <div className="select-card-body">
          <StyledSelect
            id="location-select"
            autoFocus={false}
            onChange={handleLocationChange}
            options={locationNames.map((name) => ({ value: name, label: name }))}
          />
        </div>

      </Card>

      {selectedLocation && (
      <>
        <Card header={t('selectedLocationAddress')}>
          {selectedLocation.commandCenters.length !== 0
                && (
                  <div className="button-container">
                    <BlueButton onClick={invertAllExpanded} text={oneExpanded() ? t('collapseAll') : t('expandAll')} />
                  </div>
                )}
          <div className="command-centers-data">
            {selectedLocation.commandCenters.length === 0
                && (
                <div className="text-container">
                  <h2 className="large-text">{t('noCommandCentersFound')}</h2>
                </div>
                )}
            {selectedLocation.commandCenters.sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              }
              return -1;
            }).map((center, index) => (
              <CollapsibleRow
                    // eslint-disable-next-line react/no-array-index-key
                key={`${center.name}-${index}`}
                header={<p className="dark-blue-text">{center.name}</p>}
                isExpanded={expandedRows[index] === true}
                invertExpanded={handleRowExpandedChange}
                rowIndex={index}
              >
                <div className="center-details">
                  <div>
                    <h3 className="medium-text">
                      {t('address')}
                    </h3>
                    <div className="address-contact-row">
                      <div className="contact-container">
                        <a
                          className="dark-blue-text"
                          alt={`${t('openMapsFor')} ${center.address}`}
                          target="_blank"
                          href={sanitizeHTML(`https://maps.google.com/?q=${center.address}`)}
                          rel="noreferrer"
                        >
                          <p className="no-margin-bottom no-margin-top large-text dark-blue-text">
                            {center.address}
                          </p>
                          <SiGooglemaps />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="medium-text">
                      {t('contactNumbers')}
                    </h3>
                    <div className="address-contact-row">
                      {center.contactNumbers.map((number) => (
                        <div key={number} className="contact-container">
                          <a className="dark-blue-text" alt={`${t('call')} ${number}`} href={sanitizeHTML(`tel:${number}`)}>
                            <p className="no-margin-bottom no-margin-top dark-blue-text large-text">{formatPhoneNumber(number)}</p>
                            <FiPhone className="large-icon" />
                          </a>
                        </div>

                      ))}
                    </div>
                  </div>
                </div>

              </CollapsibleRow>

            ))}
          </div>

        </Card>
      </>
      )}
    </div>

  );
};

export default CommandCentersPage;
