import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import updateToast from '../../services/toastService';
import axios from '../../services/Api';
import PageSvg from '../../assets/bcc-quick-reference/page.svg';
import '../../styles/components/pages/BCCQuickReference.scss';
import downloadDocument from '../../hooks/downloadDocumentHook';

/**
 * Renders the download button
 * @param {*} text - file name
 * @param {*} t - translation function
 * @param {*} setIsDownloadLoading
 * @param {*} isDownloadLoading
 * @param {*} index
 * @returns download button with functionality
 */
const downloadButton = (text, t, setIsDownloadLoading, isDownloadLoading, index) => {
  const handleLoad = (state) => {
    const temp = [...isDownloadLoading];
    temp[index] = state;
    setIsDownloadLoading(temp);
  };
  const handleDownload = async (docName) => {
    const toastId = toast.loading(t('downloadInProgress'));
    handleLoad(true);
    try {
      await downloadDocument(`/emergency-procedures/${docName}`, `emergency-procedure-${docName}`);
      updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
    } catch (err) {
      updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
    } finally {
      handleLoad(false);
    }
  };
  return (
    <button type="button" className="download-button" onClick={() => handleDownload(text)} key={text} disabled={isDownloadLoading[index]}>
      {!isDownloadLoading[index] && <h2>{text}</h2>}
      {isDownloadLoading[index] && <h2>{t('loading')}</h2>}
      <div className="icons">
        <img src={PageSvg} alt={t('downloadPdf')} />
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </button>
  );
};

/**
 * Renders the EmergencyProceduresPage
 * @returns
 */
const EmergencyProceduresPage = () => {
  const [procedures, setProcedures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDownloadLoading, setIsDownloadLoading] = useState([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    const abortController = new AbortController();

    const getProcedures = async () => {
      try {
        const { data } = await axios.get('/emergency-procedures', { signal: abortController.signal });
        if (data) {
          setProcedures(data);
          setIsDownloadLoading(Array(data.length).fill());
        }
        setLoading(false);
      } catch (error) {
        if (error.name === 'CanceledError') return;
        toast(t('procedureError'), {
          type: 'error',
        });
      }
    };

    getProcedures();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="bcc-quick-reference">
      <div className="bcc-header" />
      {procedures.length > 0 && !loading
      && (
      <div className="links-container">
        {procedures && procedures.filter((name) => name !== null)
          .map((name, index) => downloadButton(
            name, t, setIsDownloadLoading, isDownloadLoading, index,
          ))}
      </div>
      )}
      {procedures.length === 0 && !loading
        && <p>{t('noProcedures')}</p>}
      {loading
      && <p>{t('loading')}</p>}
    </div>
  );
};

export default EmergencyProceduresPage;
