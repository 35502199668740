import React from 'react';
import PropTypes from 'prop-types';
import IconColours from './IconColours';

/** IMT Icon */
const IncidentManagementTeamIcon = ({
  width, height, title, className, color,
}) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <title>{title}</title>
    <path
      d="M6 15.3333C6 14.2725 6.42143 13.2551 7.17157 12.5049C7.92172 11.7548 8.93913 11.3333 10 11.3333H21.624C22.7356 11.3333 23.8194 11.6806 24.724 12.3267L31.276 17.0067C32.1806 17.6528 33.2644 18 34.376 18H54C55.0609 18 56.0783 18.4214 56.8284 19.1716C57.5786 19.9217 58 20.9391 58 22V48.6667C58 49.7275 57.5786 50.745 56.8284 51.4951C56.0783 52.2452 55.0609 52.6667 54 52.6667H10C8.93913 52.6667 7.92172 52.2452 7.17157 51.4951C6.42143 50.745 6 49.7275 6 48.6667V15.3333Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.548 42.916V26.9333L24.548 42.9334L32.548 26.9573V42.9334M36.852 26.9333H47.452M42.152 42.9334V26.9333"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IncidentManagementTeamIcon.defaultProps = {
  width: 64,
  height: 64,
  title: '',
  className: undefined,
  color: IconColours.WHITE,
};

IncidentManagementTeamIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default IncidentManagementTeamIcon;
