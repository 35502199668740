import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import muiTheme from '../../muiTheme';
import Header from '../Header';
import Footer from '../Footer';
import Login from '../Login';

/**
   * React render method
   * @return {JSX} component jsx
   */
const LoginPage = () => {
  const { t } = useTranslation('common');
  return (
    <ThemeProvider theme={muiTheme}>
      <Header />
      <div className="page-container">
        <div className="page-content">
          <Login title={t('azureLogin')} />
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default LoginPage;
