import ExcelJS from 'exceljs';
import { downloadDocumentBuffer } from './downloadDocumentHook';

/**
 * Downloads Excel sheet given data in styled format
 */

export default async (data, headers, subHeaders, fileName, sheetName) => {
  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet(sheetName);
  const worksheet = workbook.getWorksheet(sheetName);
  worksheet.properties.defaultColWidth = 20;
  worksheet.addRow(headers);
  worksheet.addRow(subHeaders);
  worksheet.mergeCells('H1', 'I1');
  worksheet.mergeCells('J1', 'K1');
  worksheet.mergeCells('L1', 'M1');
  worksheet.mergeCells('N1', 'Q1');
  worksheet.addRows(data);
  for (let i = 1; i < data.length + 3; i += 1) {
    if (i % 2 === 0) {
      worksheet.getRow(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D7EFFF' },
      };
    }
    worksheet.getRow(i).width = 20;
    worksheet.getRow(i).border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };
  }

  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2683BE' },
  };
  worksheet.getRow(2).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '68A8D2' },
  };
  worksheet.getRow(1).font = {
    bold: true,
  };
  worksheet.getRow(2).font = {
    bold: true,
  };

  worksheet.getRow('1').border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } },
  };
  worksheet.getRow('2').border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } },
  };
  const buffer = await workbook.xlsx.writeBuffer();
  downloadDocumentBuffer(buffer, `${fileName}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
};
