import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPhone } from 'react-icons/fi';
import { LuChevronRight } from 'react-icons/lu';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import axios from '../../services/Api';
import Card from '../Card';
import BlueButton from '../BlueButton';
import CollapsibleRow from '../CollapsibleRow';
import formatPhoneNumber from '../../helpers/phone';
import '../../styles/components/pages/MyTeamsPage.scss';
import ContactCard from '../ContactCard';

/**
* MyTeamsPage
* @return {JSX} component jsx
*/
const MyTeamsPage = () => {
  const { t } = useTranslation('common');
  const [expandedRows, setExpandedRows] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const { user } = useContext(UserContext);
  const [contactCardInfo, setContactCardInfo] = useState({});

  useEffect(() => {
    const abortController = new AbortController();
    const getTeamData = async () => {
      try {
        const { data } = await axios.get(`/directory/business-teams/vp/${user._id}`, { signal: abortController.signal });
        if (data) {
          setTeamData(data);
        }
        setLoading(false);
      } catch (error) {
        if (error.name === 'CanceledError') return;
        toast(t('myTeamsError'), {
          type: 'error',
        });
      }
    };

    getTeamData();
    return () => {
      abortController.abort();
    };
  }, []);

  /**
   * Displays a lead or an altnerate
   * @param {*} team
   * @param {*} loc
   * @param {*} leadOrAlt
   * @returns row content to be displayed
   */
  const displayLeadOrAlt = (team, loc, leadOrAlt) => (
    <button
      type="button"
      key={`${team}-${loc}-${leadOrAlt}`}
      className="business-team-lead-alt-container"
      onClick={() => (leadOrAlt.firstName ? setContactCardInfo(leadOrAlt) : null)}
    >
      <p className="business-team-row-lead-alt dark-blue-text">{ leadOrAlt.firstName ? `${leadOrAlt.firstName} ${leadOrAlt.lastName}` : leadOrAlt}</p>
      <div className="phone-container">
        <p>{leadOrAlt.mainPhone && formatPhoneNumber(leadOrAlt.mainPhone?.number)}</p>
        {leadOrAlt.mainPhone && <FiPhone />}
      </div>
      {leadOrAlt.firstName && <LuChevronRight />}
    </button>
  );

  const handleRowExpandedChange = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const oneExpanded = () => {
    const expansions = Object.values(expandedRows);

    if (expansions.length === 0) {
      return false;
    }

    return expansions.some((expansion) => expansion === true);
  };

  const invertAllExpanded = () => {
    const isOneExpanded = oneExpanded();
    const newExpandedRows = { ...expandedRows };

    teamData.forEach((_, index) => {
      newExpandedRows[index] = !isOneExpanded;
    });
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className="my-teams-page">
      {isLoading ? (
        <div className="text-container">
          <h2 className="large-text">{t('loading')}</h2>
        </div>
      ) : (
        Object.keys(contactCardInfo).length === 0 && (
        <Card header={t('businessTeams')}>
          {teamData.length > 0
            ? (
              <>
                <div className="button-container">
                  <BlueButton onClick={invertAllExpanded} text={oneExpanded() ? t('collapseAll') : t('expandAll')} />
                </div>
                <div className="team-data">
                  {teamData.map((team, index) => (
                    <CollapsibleRow
                    // eslint-disable-next-line react/no-array-index-key
                      key={`${team.name}-${index}`}
                      isExpanded={expandedRows[index] === true}
                      invertExpanded={handleRowExpandedChange}
                      rowIndex={index}
                      header={<p className="dark-blue-text">{team.name}</p>}
                    >
                      <div>
                        {team.locations.map((loc) => (
                          <div key={`${team.name}-${loc.location}`} className="business-team-row-container">
                            <p className="business-team-row-location">{loc.location}</p>
                            <h3 className="medium-text">{t('leads')}</h3>
                            {loc.lead.map((lead) => (
                              displayLeadOrAlt(team.name, loc.location, lead)
                            ))}
                            <h3 className="medium-text">{t('alternates')}</h3>
                            {loc.alternate.map((alt) => (
                              displayLeadOrAlt(team.name, loc.location, alt)
                            ))}
                          </div>
                        ))}
                      </div>
                    </CollapsibleRow>
                  ))}
                </div>
              </>
            )
            : (
              <div className="text-container">
                <h2 className="large-text">{t('noVpTeamsFound')}</h2>
              </div>
            )}
        </Card>
        )
      )}
      {Object.keys(contactCardInfo).length !== 0 && (
      <ContactCard user={contactCardInfo} handleClose={() => setContactCardInfo({})} />
      )}
    </div>
  );
};

export default MyTeamsPage;
