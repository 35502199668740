import axios from '../services/Api';

/**
 * fetches external support data
*/

export const getExternalSupport = async (page, location, signal, name, type) => {
  let urlWithParams = `/external-support/${page}?location=${location}`;
  if (name !== undefined && name !== '') {
    urlWithParams += `&name=${name}`;
  }
  if (type !== undefined && type !== '') {
    urlWithParams += `&type=${type}`;
  }
  const { data } = await axios.get(urlWithParams, { signal });
  return (data);
};

/**
 * fetches all locations external support data
*/
export const getExternalSupportLocations = async (signal) => {
  const { data } = await axios.get('/external-support/locations', { signal });
  return (data);
};
