import { createContext } from 'react';
/**
 * a context for page access
 */
const PageAccessContext = createContext({
  pageAccess: {},
  pageAccessLoading: false,
  pageAccessError: false,
});

export default PageAccessContext;
