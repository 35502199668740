import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaLink } from 'react-icons/fa';
import Card from '../Card';
import '../../styles/components/pages/LifeWorksPage.scss';

/**
 * LifeWorksPage
 * @returns JSX component
 */
const LifeWorksPage = () => {
  const { t } = useTranslation();

  return (
    <Card className="life-works-card">
      {/** $nbsp; indicates and empty space */}
      <div className="telus-header">
        {t('EAP')}
      </div>
      <div className="life-works-click-source-container">
        <p className="click-source">
          <a href="https://cooperators.sharepoint.com/sites/hr-wellness/SitePages/LifeWorks.aspx?csf=1&web=1&e=1j7fR4&CID=ce1b42d9-0080-435b-b724-9153d73a1500" target="_blank" rel="noopener noreferrer">
            {t('lifeWorksClick')}
            <FaLink />
          </a>
&nbsp;

        </p>
        <div className="click-source-paragraph-container">
          <p className="click-source-paragraph">{t('TelusParagraph')}</p>
        </div>
      </div>
      <div className="telus-container">
        <p className="telus-Available">
          {t('telusAvailable')}
        </p>
        <div className="life-works-user">
          <ul>
            <li>{t('telusList1')}</li>
            <li>
              <Trans i18nKey="telusList2">
                <a href="https://login.lifeworks.com" target="_blank" rel="noopener noreferrer">Visit TELUS Health online</a>
                <a href="https://cooperators.sharepoint.com/sites/hr-wellness/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fhr%2Dwellness%2FShared%20Documents%2FLifeWorks%2FTH%2DDLAppFlyer%2DCAN%5FENG%2DEmail%2DV2%2D2023%2E07%2E26%2Epdf&parent=%2Fsites%2Fhr%2Dwellness%2FShared%20Documents%2FLifeWorks" target="_blank" rel="noopener noreferrer">
                  mobile app
                </a>
                <a href="https://apps.apple.com/ca/app/telus-health-one/id662088737" target="_blank" rel="noopener noreferrer"> Apple </a>
                <a href="https://play.google.com/store/apps/details?id=com.wam.android&pli=1" target="_blank" rel="noopener noreferrer"> Android </a>
              </Trans>
            </li>
          </ul>
        </div>

      </div>
    </Card>
  );
};

export default LifeWorksPage;
