import { toast } from 'react-toastify';

/**
 * Update a react-toastify toast
 * @param {String} toastId - Id of the toast to update
 * @param {String|function} toastMessage - Message to render in the toast
 * @param {String} toastType - Type of toast to show
 * @param {Object} options - additional options to pass to the updated toast
 */
const updateToast = (toastId, toastMessage, toastType = toast.TYPE.DEFAULT, options = {}) => {
  toast.update(toastId, {
    autoClose: null,
    isLoading: false,
    transition: null,
    closeButton: null,
    closeOnClick: null,
    ...options,
    render: toastMessage,
    type: toastType,
  });
};

export default updateToast;
