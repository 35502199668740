import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DirectoryItemMulti, { DirectoryItemSingle } from './DirectoryItem';
import SearchBar from './SearchBar';
import BlueButton from './BlueButton';

import '../styles/components/DirectoryFilterSelect.scss';

const DirectoryFilterSelect = ({
  name, options, selectedOptions, setSelected, setOpen, isMulti,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [allNone, setAllNone] = useState(false);
  const [selectedTemp, setSelectedTemp] = useState([]);

  const handleSelectAllNone = () => {
    setSelectedTemp(options.map(() => !allNone));
    setAllNone(!allNone);
  };

  const handleCheck = (i) => {
    const temp = [...selectedTemp];
    temp[i] = !temp[i];
    setSelectedTemp(temp);
  };

  useEffect(() => {
    setSelectedTemp(options.map(
      (option) => selectedOptions.findIndex((o) => o === (option.value || option)) !== -1,
    ));
  }, [selectedOptions, options]);

  return (
    <div className="directory-filter-select">
      <div className="filters-header">
        <div className="filter-btn-left">
          <BlueButton text={t('cancel')} onClick={() => setOpen(false)} />
        </div>
        <h2>{t('filter', { name })}</h2>
        {isMulti && (
        <div className="filter-btn-right">
          <BlueButton
            text={t('ok')}
            onClick={() => {
              setSelected(selectedTemp);
              setOpen(false);
            }}
          />
        </div>
        )}
      </div>
      {isMulti && (
      <p className="selected-amount">
        {`${selectedTemp.filter((select) => select).length} ${t('selected')}`}
      </p>
      )}
      <div className="items-container">
        <SearchBar
          placeholder={t('search')}
          label={t(`${name}Search`)}
          type="text"
          value={search}
          disabled={false}
          onChange={setSearch}
        />
        {isMulti && (
          <>
            <DirectoryItemMulti
              check={allNone}
              setCheck={handleSelectAllNone}
              header={t('selectAllNone')}
            />
            {options.map((opt) => opt.display || opt).map((option, i) => (
              option.toLowerCase().includes(search.toLowerCase()) && (
              <DirectoryItemMulti
                key={option}
                check={selectedTemp[i]}
                setCheck={() => handleCheck(i)}
                header={option}
                isCheck
              />
              )
            ))}
          </>
        )}
        {!isMulti && (
          <>
            {options.map((option) => (
              option.toLowerCase().includes(search.toLowerCase()) && (
              <DirectoryItemSingle
                key={option}
                header={option}
                onClick={() => {
                  setSelected(option);
                  setOpen(false);
                }}
              />
              )
            ))}
          </>
        )}
      </div>
    </div>
  );
};

DirectoryFilterSelect.defaultProps = {
  name: '',
  options: [],
  selectedOptions: [],
  setSelected: () => { },
  setOpen: () => { },
  isMulti: false,
};
DirectoryFilterSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    display: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })])),
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  setSelected: PropTypes.func,
  setOpen: PropTypes.func,
  isMulti: PropTypes.bool,
};

export default DirectoryFilterSelect;
