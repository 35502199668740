import React from 'react';
import PropTypes from 'prop-types';
import { FiPhone } from 'react-icons/fi';
import { SiGooglemaps } from 'react-icons/si';
import sanitizeHTML from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { AiOutlineMail } from 'react-icons/ai';
import { IoChatbubbleOutline } from 'react-icons/io5';

import '../styles/components/ContactRow.scss';

const ContactRow = ({
  name, contacts, secondaryText,
}) => {
  const { t } = useTranslation();
  const contact = (type, data) => {
    switch (type) {
      case 'address':
        return (
          <a
            className="dark-blue-text"
            alt={t('openMapsFor', { data })}
            target="_blank"
            href={sanitizeHTML(`https://maps.google.com/?q=${data}`)}
            rel="noreferrer"
          >
            <SiGooglemaps />
          </a>
        );
      case 'phone':
        return (<a className="dark-blue-text" aria-label={`${t('call')} ${data}`} href={sanitizeHTML(`tel:${data}`)}><FiPhone /></a>);
      case 'text':
        return (<a aria-label={t('message', { data })} href={sanitizeHTML(`sms:${data}`)}><IoChatbubbleOutline /></a>);
      case 'email':
        return (<a aria-label={t('email', { data })} href={sanitizeHTML(`mailto:${data}`)}><AiOutlineMail /></a>);
      default:
        return (<></>);
    }
  };

  return (
    <div className="contact-row">
      <div className="contact-row-content">
        <span>
          {name}
          <span className="secondary-text">{secondaryText}</span>
        </span>
        <div className="contact-row-icons">
          {contacts.length > 0 && contact(contacts[0].type, contacts[0].data)}
          {contacts.length > 1
          && (
          <div className="second">
            {contact(contacts[1].type, contacts[1].data)}
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

ContactRow.defaultProps = {
  name: '',
  contacts: [],
  secondaryText: '',
};

ContactRow.propTypes = {
  name: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
  })),
  secondaryText: PropTypes.string,
};

export default ContactRow;
