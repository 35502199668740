import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from '../services/Api';
import PageAccessContext from '../contexts/PageAccessContext';

/** Page Access Provider */
const PageAccessProvider = ({ children }) => {
  const [pageAccess, setPageAccess] = useState({});
  const [pageAccessLoading, setPageAccessLoading] = useState(true);
  const [pageAccessError, setPageAccessError] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    const abortController = new AbortController();
    setPageAccessLoading(true);
    const fetchUser = async () => {
      try {
        const { data } = await axios.get('/page-access', { signal: abortController.signal });
        setPageAccess(data);
        setPageAccessError(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('loadingPageAccessError'));
        setPageAccessError(true);
      }
      setPageAccessLoading(false);
    };
    fetchUser();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <PageAccessContext.Provider
      value={{
        pageAccess, pageAccessLoading, pageAccessError,
      }}
    >
      {children}
    </PageAccessContext.Provider>
  );
};

PageAccessProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default PageAccessProvider;
