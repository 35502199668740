import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IoCaretForwardSharp } from 'react-icons/io5';

import '../styles/components/NavMenuItem.scss';

/**
 * Navigation menu item component
 * @param {object} props
 * @returns {JSX} JSX to render the component
 */
const NavMenuItem = ({
  title, link, svg, onClick, className, rightArrow,
}) => {
  const { t } = useTranslation();
  if (link) {
    return (
      <Link to={link} onClick={onClick} className={className}>
        {svg && (typeof svg === 'string' ? <img src={svg} alt={t('navIcon')} /> : React.cloneElement(svg, { title: t('navIcon') }))}
        <span className="nav-item-label">
          {title}
          {rightArrow && <IoCaretForwardSharp size="2em" />}
        </span>
      </Link>
    );
  }
  if (onClick) {
    return (
      <button type="button" onClick={onClick} className={`button ${className}`} aria-label={t('navIcon')}>
        {svg && (typeof svg === 'string' ? <img src={svg} alt={t('navIcon')} /> : React.cloneElement(svg, { title: t('navIcon') }))}
        <span className="nav-item-label">
          {title}
          {rightArrow && <IoCaretForwardSharp size="2em" />}
        </span>
      </button>
    );
  }

  return (<div className={className} data-testid="template" />);
};

NavMenuItem.defaultProps = {
  title: '',
  link: '/',
  svg: null,
  onClick: null,
  className: '',
  rightArrow: false,
};
NavMenuItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  // The image to render
  // Strings are wrapped in a img tag, while elements are cloned with React.cloneElement
  svg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  rightArrow: PropTypes.bool,
};

export default NavMenuItem;
