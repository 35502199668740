import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavMenu from '../NavMenu';
import { getActiveEvents, getCurrentEvents } from '../../hooks/EventHooks';
import UserContext from '../../contexts/UserContext';
import KDCLogo from '../KDCLogo';
import PageAccessContext from '../../contexts/PageAccessContext';

import '../../styles/components/pages/HomePage.scss';

const HomePage = () => {
  const { t } = useTranslation();
  const { pageAccess, pageAccessLoading } = useContext(PageAccessContext);
  const { user, updateUser } = useContext(UserContext);
  const [eventNums, setEventNums] = useState(0);
  const [ids, setIds] = useState([]);
  const [isEvents, setIsEvents] = useState(false);
  const handleEvents = async () => {
    try {
      const access = pageAccess && pageAccess.StartEventPage === true;
      const events = access ? await getActiveEvents() : await getCurrentEvents();
      setIsEvents(events.length > 0);
      const idsList = events.map((event) => event._id);
      setIds(idsList);
      const filtered = idsList.filter((id) => (!user.seenEvents?.includes(id)));
      setEventNums(filtered.length);
    } catch {
      toast.error(t('loadingSeenEventsError'));
    }
  };
  const onClick = async () => {
    try {
      await updateUser({ seenEvents: ids });
    } catch {
      toast.error(t('updatingSeenEventsError'));
    }
  };
  useEffect(() => {
    handleEvents();
  }, [pageAccess, user]);
  return (
    <div className="homepage">
      {isEvents > 0 && (
      <Link className="current-events" to="/events" onClick={onClick}>
        <h1>{t('currentEvents')}</h1>
        {eventNums > 0 && (
        <div className="event-number-icon">
          <span>{eventNums}</span>
        </div>
        )}
      </Link>
      )}
      {!pageAccessLoading && (
      <div className="nav-items">
        <NavMenu forComponent="HomePage" />
      </div>
      )}
      {pageAccessLoading && <KDCLogo spinning />}
    </div>
  );
};

export default HomePage;
