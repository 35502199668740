import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import {
  AiOutlineTeam,
  AiOutlineUser,
} from 'react-icons/ai';
import { IoCaretBackSharp } from 'react-icons/io5';
import { BiWorld } from 'react-icons/bi';
import { FaLaptop, FaRegFilePdf, FaRegHeart } from 'react-icons/fa';
import { FiArchive } from 'react-icons/fi';
import { RiHealthBookLine } from 'react-icons/ri';
import { MdOutlineEmergency, MdOutlinePrint } from 'react-icons/md';
import PropTypes from 'prop-types';
import PageAccessContext from '../contexts/PageAccessContext';
import UserContext from '../contexts/UserContext';
import updateToast from '../services/toastService';
import downloadDocument from '../hooks/downloadDocumentHook';
import NavigationMenuItem from './NavMenuItem';

import {
  MegaphoneIcon,
  IncidentManagementTeamIcon,
  BCCQuickReferenceIcon,
  MediaDosAndDontsIcon,
  GearIcon,
  DecisionMakersIcon,
  EssentialBusinessPartnersIcon,
  IconColours,
} from './icons';

import homeSvg from '../assets/images/nav-icons/home.svg';
import commandCentersSvg from '../assets/images/nav-icons/commandCenters.svg';
import directorySvg from '../assets/images/nav-icons/directory.svg';

/**
 *
 * @param {onClick, forComponent} props
 * @returns {JSX} JSX to render the component
 */

const NavMenu = ({ onClick, forComponent }) => {
  const { t } = useTranslation('common');
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { pageAccess } = useContext(PageAccessContext);
  const { user } = useContext(UserContext);
  const [isAdminMenu, setIsAdminMenu] = useState(false);

  const fetchMediaDoc = async () => {
    await setIsDownloadLoading(true);
    const toastId = toast.loading(t('downloadInProgress'));
    try {
      await downloadDocument('/constants/document/media-dos-and-donts', 'MediaDosAndDonts');
      updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
    } catch (err) {
      updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
    } finally {
      setIsDownloadLoading(false);
      onClick();
    }
  };
  // items that will be output in the hamburger menu
  // requires a title, link and svg, is passed to HamburgerMenuItem
  const menuItems = [
    {
      title: t('home'),
      link: '/',
      svg: homeSvg,
      onClick,
      pageAccess: forComponent !== 'HomePage',
    },
    {
      title: t('startEvent'),
      link: '/start-event',
      svg: <MegaphoneIcon />,
      onClick,
      pageAccess: pageAccess?.StartEventPage,
    },
    {
      title: t('incidentsManagementTeam'),
      link: '/incidents-management-teams',
      svg: <IncidentManagementTeamIcon />,
      onClick,
      pageAccess: pageAccess?.IncidentManagementTeamPage,
    },
    {
      title: t('myTeams'),
      link: '/my-teams',
      svg: <AiOutlineTeam />,
      onClick,
      pageAccess: pageAccess?.VpTeamsPage,
    },
    {
      title: t('commandCenters'),
      link: '/command-centers',
      svg: commandCentersSvg,
      onClick,
      pageAccess: pageAccess?.CommandCentersPage,
    },
    {
      title: t('directory'),
      link: '/directory',
      svg: directorySvg,
      onClick,
      pageAccess: pageAccess?.DirectoryPage,
    },
    {
      title: t('externalSupport'),
      link: '/external-support',
      svg: <RiHealthBookLine />,
      onClick,
      pageAccess: pageAccess?.ExternalSupportPage,
    },
    {
      title: t('emergencyProcedures'),
      link: '/emergency-procedures',
      svg: <MdOutlineEmergency />,
      onClick,
      pageAccess: pageAccess?.EmergencyProceduresPage,

    },
    {
      title: t('bccQuickReference'),
      link: '/bcc-quick-reference',
      svg: <BCCQuickReferenceIcon />,
      onClick,
      pageAccess: pageAccess?.BCCQuickReferenceDoc,
    },
    {
      title: !isDownloadLoading ? t('mediaDosandDonts') : t('loading'),
      link: null,
      svg: <MediaDosAndDontsIcon />,
      onClick: () => {
        if (!isDownloadLoading) {
          fetchMediaDoc();
        }
      },
      pageAccess: pageAccess?.MediaDosAndDontsDoc,
    },
    {
      title: t('redBookPrint'),
      link: null,
      svg: <MdOutlinePrint />,
      onClick: () => {},
      pageAccess: forComponent !== 'HamburgerMenu' && pageAccess?.VpTeamsPage,
    },
    {
      title: t('lifeWorks'),
      link: '/life-works',
      svg: <FaRegHeart />,
      onClick,
      pageAccess: pageAccess?.LifeWorksPage,
    },
    {
      title: t('admin'),
      svg: <GearIcon width={64} height={64} color={IconColours.WHITE} />,
      onClick: () => setIsAdminMenu(true),
      link: null,
      rightArrow: true,
      pageAccess: user?.isAdmin,
    },
  ].filter((item) => item.pageAccess);

  const adminMenuItems = [
    {
      title: t('back'),
      svg: <IoCaretBackSharp size="2em" />,
      onClick: () => setIsAdminMenu(false),
      link: null,
      className: 'back-button',
    },
    {
      title: t('userDatabase'),
      svg: <AiOutlineUser />,
      className: 'section-break',
      link: '/admin/user-database',
      onClick,
    },
    {
      title: t('decisionMakers'),
      svg: <DecisionMakersIcon />,
      link: '/admin/decision-makers',
      onClick,
    },
    {
      title: t('locations'),
      svg: <BiWorld />,
    },
    {
      title: t('businessTeams'),
      svg: <AiOutlineTeam />,
    },
    {
      title: t('essentialBusinessPartners'),
      svg: <EssentialBusinessPartnersIcon />,
    },
    {
      title: t('itRequirements'),
      svg: <FaLaptop />,
      className: 'section-break',
    },
    {
      title: t('bcmTemplates'),
      svg: <FaRegFilePdf />,
    },
    {
      title: t('bccQuickReference'),
      link: '/bcc-quick-reference',
      svg: <BCCQuickReferenceIcon color={IconColours.BLUE} />,
      onClick,
    },
    {
      title: t('incidentsManagementTeam'),
      link: '/incidents-management-teams',
      svg: <IncidentManagementTeamIcon color={IconColours.BLUE} />,
      onClick,
    },
    {
      title: t('mediaDosandDonts'),
      svg: <MediaDosAndDontsIcon color={IconColours.BLUE} />,
      className: 'section-break',
    },
    {
      title: t('emergencyProcedures'),
      link: '/emergency-procedures',
      svg: <MdOutlineEmergency />,
      onClick,
    },
    {
      title: t('constants'),
      svg: <FiArchive />,
    },
    {
      title: t('events'),
      svg: <MegaphoneIcon color={IconColours.BLUE} />,
    },
    {
      title: t('externalSupport'),
      link: '/external-support',
      svg: <RiHealthBookLine />,
      onClick,
    },
  ];

  const homePageNavRender = (items) => {
    // Home page displays in rows of 6, pad the extra cells with templates
    const rowSize = 6;
    const remainder = items.length % rowSize;
    const fill = items.concat(Array(remainder > 0 ? rowSize - remainder : 0).fill(null));
    return (
      fill.map((item, ind) => {
        if (item) {
          return (
            <NavigationMenuItem
              key={item.title}
              title={item.title}
              link={item.link}
              svg={item.svg}
              onClick={item.onClick}
              rightArrow={item.rightArrow}
              className={`homepage-item homepage-item-${isAdminMenu ? 'admin' : 'user'}${item.className ? ` ${item.className}` : ''}`}
            />
          );
        }
        return (
          <NavigationMenuItem
            key={`temp${ind * 2}`}
            link={null}
            className={`homepage-item ${isAdminMenu ? ' template-admin' : ' template-user'}`}
          />
        );
      })
    );
  };

  const items = isAdminMenu ? adminMenuItems : menuItems;
  const iconContextValue = isAdminMenu ? { size: '64', color: IconColours.BLUE } : { size: '64' };
  return (
    <IconContext.Provider value={iconContextValue}>
      {forComponent === 'HamburgerMenu' && items.map((item) => (
        <NavigationMenuItem
          key={item.title}
          title={item.title}
          link={item.link}
          svg={item.svg}
          onClick={item.onClick}
          rightArrow={item.rightArrow}
          className={`hamburger-menu-item hamburger-menu-item-${isAdminMenu ? 'admin' : 'user'}${item.className ? ` ${item.className}` : ''}`}
        />
      ))}
      {forComponent === 'HomePage' && items && homePageNavRender(items)}
    </IconContext.Provider>
  );
};

NavMenu.defaultProps = {
  onClick: () => {},
  forComponent: '',
};
NavMenu.propTypes = {
  onClick: PropTypes.func,
  forComponent: PropTypes.oneOf(['HamburgerMenu', 'HomePage']),
};

export default NavMenu;
