/**
 * Formats a phone number
 * @param {string} contactNumber - the number to format like 5555555555.
 * If the number begins with a 1, it will be formatted as a country code.
 * If the number has an extension (is longer than 10 digits), it will also be displayed.
 * @return {string} - formatted number like 555-555-5555
 */
const formatPhoneNumber = (contactNumber) => {
  let intSign = '';
  let contact = contactNumber;
  if (contactNumber[0] === '1') {
    intSign = `+${contact[0]} `;
    contact = contact.slice(1);
  }
  let number = (`${contact}`).replace(/\D/g, '');
  if (contact.length === 10) {
    number = `${intSign}(${contact.slice(0, 3)}) ${contact.slice(3, 6)}-${contact.slice(6, 10)}`;
  }
  return number;
};

export default formatPhoneNumber;
