import React from 'react';
import PropTypes from 'prop-types';
import IconColours from './IconColours';

/** EBP Icon */
const EssentialBusinessPartnersIcon = ({
  width, height, title, className, color,
}) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <title>{title}</title>
    <path
      d="M57.8933 23.2266C61.2266 19.8933 59.7066 16 57.8933 14.1066L49.8933 6.10664C46.5333 2.77331 42.6666 4.29331 40.7733 6.10664L36.24 10.6666H29.3333C24.2666 10.6666 21.3333 13.3333 19.84 16.4L7.99997 28.24V38.9066L6.10664 40.7733C2.77331 44.1333 4.29331 48 6.10664 49.8933L14.1066 57.8933C15.5466 59.3333 17.0933 59.8666 18.56 59.8666C20.4533 59.8666 22.1866 58.9333 23.2266 57.8933L30.4266 50.6666H40C44.5333 50.6666 46.8266 47.84 47.6533 45.0666C50.6666 44.2666 52.32 41.9733 52.9866 39.7333C57.12 38.6666 58.6666 34.7466 58.6666 32V24H57.0933L57.8933 23.2266ZM53.3333 32C53.3333 33.2 52.8266 34.6666 50.6666 34.6666H48V37.3333C48 38.5333 47.4933 40 45.3333 40H42.6666V42.6666C42.6666 43.8666 42.16 45.3333 40 45.3333H28.24L19.4933 54.08C18.6666 54.8533 18.1866 54.4 17.8933 54.1066L9.91997 46.16C9.14664 45.3333 9.59997 44.8533 9.89331 44.56L13.3333 41.0933V30.4266L18.6666 25.0933V29.3333C18.6666 32.56 20.8 37.3333 26.6666 37.3333C32.5333 37.3333 34.6666 32.56 34.6666 29.3333H53.3333V32ZM54.1066 19.44L49.5733 24H29.3333V29.3333C29.3333 30.5333 28.8266 32 26.6666 32C24.5066 32 24 30.5333 24 29.3333V21.3333C24 20.1066 24.4533 16 29.3333 16H38.4266L44.5066 9.91997C45.3333 9.14664 45.8133 9.59997 46.1066 9.89331L54.08 17.84C54.8533 18.6666 54.4 19.1466 54.1066 19.44Z"
      fill={color}
    />
  </svg>
);

EssentialBusinessPartnersIcon.defaultProps = {
  width: 64,
  height: 64,
  title: '',
  className: undefined,
  color: IconColours.BLUE,
};

EssentialBusinessPartnersIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default EssentialBusinessPartnersIcon;
