import { createContext } from 'react';

/** A context for user data */
const UserContext = createContext({
  user: {},
  userLoading: false,
  userError: false,
  updateUser: () => {},
});

export default UserContext;
