import React from 'react';
import PropTypes from 'prop-types';
import { MdSearch } from 'react-icons/md';
import { FiXCircle } from 'react-icons/fi';
import TextareaAutosize from 'react-textarea-autosize';
import '../styles/components/SearchBar.scss';

const SearchBar = ({
  placeholder, label, type, value, onChange,
  disabled, id, limit, isSearchIcon, isTextArea, dynamicHeader,
}) => (
  <label htmlFor={id} className="search-bar-component">
    {value.length > 0 && <span className="dynamic-header">{dynamicHeader}</span>}
    {isSearchIcon && <MdSearch className="search-bar-svg" />}
    {!isTextArea ? (
      <input
        maxLength={limit}
        type={type}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        aria-placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="input"
        id={id}
        aria-label={label}
      />
    ) : (
      <TextareaAutosize
        maxLength={limit}
        type={type}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        className="input"
        id={id}
        aria-label={label}
      />
    )}
    {value && (
      <button type="button" onClick={() => onChange('')} className="cancel-button-search-bar">
        <FiXCircle className="search-bar-svg" />
      </button>
    )}
  </label>
);

SearchBar.defaultProps = {
  placeholder: '',
  label: '',
  type: '',
  value: '',
  onChange: null,
  disabled: false,
  id: '',
  limit: Infinity,
  isSearchIcon: true,
  isTextArea: false,
  dynamicHeader: '',
};
SearchBar.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  limit: PropTypes.number,
  isSearchIcon: PropTypes.bool,
  isTextArea: PropTypes.bool,
  dynamicHeader: PropTypes.string,
};

export default SearchBar;
