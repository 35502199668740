import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { FaXmark } from 'react-icons/fa6';
import {
  Box,
  Button,
  Modal,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { requestStartEventName, requestEndEventName } from './Api';
import Card from '../components/Card';
import CircledButton from '../components/CircledButton';

import '../styles/components/AutoLogout.scss';

const oneMinInMS = 60 * 1000;

/** Prop types for AutoLogout component  */
const propTypes = {
  /**
   * Number of minutes of inactivity before showing warning dialog.
   * */
  warningTimeInMinutes: PropTypes.number,

  /**
   * Number of minutes of inactivity before user is automatically logged out.
   * This is the minutes to wait after the warning dialog shows up.
   * */
  logoutTimeInMinutes: PropTypes.number,

  children: PropTypes.node,
};

/** Default prop values for AutoLogout component  */
const defaultProps = {
  warningTimeInMinutes: 30,
  logoutTimeInMinutes: 10,
  children: undefined,
};

/**
 * AutoLogout Component
 *
 * It wraps its children and put a dialog beside its children which
 * is use to display a warning dialog after 'x' minutes of inactivity.
 *
 * The component listens to mouse movements, keyboard inputs, and http request made with axios.
 * IMPORTANT: the axios instance must be the one exported from our api file.
 *
 * @returns {React.JSX.Element}
 */
const AutoLogout = ({
  children,
  logoutTimeInMinutes,
  warningTimeInMinutes,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(logoutTimeInMinutes * 60);
  const [showWarning, setShowWarning] = useState(false);

  const warningTimerRef = useRef(null);
  const logoutTimerRef = useRef(null);
  const countdownTimerRef = useRef(null);

  const { instance, inProgress } = useMsal();

  /**
   * Logs out the user from the application
   * @returns {void}
   */
  const logout = () => {
    if (inProgress === InteractionStatus.None) {
      instance.logoutRedirect({
        postLogoutRedirectUri: window.AZURE_REDIRECT_URL,
      });
    }
  };

  /**
   * This ref is used as a flag which halts the reset of timers.
   * Prevents event listeners from resetting the timers when warning dialog is visible.
   * Prevents timers being set when auto logout is disabled.
   */
  const haltAutoLogoutRef = useRef(false);

  /**
   * Keeps a count of how many requestStartEvent has been triggered.
   * Helps to determine for each requestStartEvent there should be
   * another requestEndEvent.
   */
  const disableCountRef = useRef(0);

  const { t } = useTranslation();

  /**
   * A list of events that triggers timer reset when warning dialog is not showing.
   */
  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  /**
   * Set a timeout for warning dialog.
   * Warning timeout hanlder handles starting a count down to
   * update minutes remaining until logout and also sets
   * a timeout for the actual logout.
   */
  const setWarningTimeout = () => {
    if (haltAutoLogoutRef.current) return;

    warningTimerRef.current = window.setTimeout(() => {
      // we halt resetting timers
      haltAutoLogoutRef.current = true;
      // open the warning dialog
      setShowWarning(true);
      // start interval count to update time left before logout
      countdownTimerRef.current = window.setInterval(
        () => setSecondsLeft((seconds) => {
          // stop count down once it reaches the logout time
          // prevent from going into the negatives
          if (seconds <= 0) return seconds;
          return seconds - 1;
        }),
        1000,
      );

      // setup a timeout to logout
      logoutTimerRef.current = window.setTimeout(
        () => (logout()),
        logoutTimeInMinutes * oneMinInMS,
      );
    }, warningTimeInMinutes * oneMinInMS);
  };

  /**
   * Removes all timers that have been set.
   */
  const removeTimers = () => {
    clearTimeout(warningTimerRef.current);
    clearTimeout(logoutTimerRef.current);
    clearInterval(countdownTimerRef.current);

    warningTimerRef.current = null;
    logoutTimerRef.current = null;
    countdownTimerRef.current = null;
  };

  /**
   * Resets all timers that have been set if auto logout is not on halt.
   */
  const resetTimers = () => {
    // Using a ref because a state becomes stale after the
    // initial setup in the useEffect bellow.
    // Halt the reset of timers if the warning dialog is showing.
    // Don't start timers if auto logout is halted.
    if (haltAutoLogoutRef.current) return;
    removeTimers();
    setWarningTimeout();
  };

  /**
   * Utility function provided as part of context value.
   * This is a soft disable of the autologout.
   * No event listeners are actually removed and only
   * timers are removed.
   */
  const disable = () => {
    disableCountRef.current += 1;
    haltAutoLogoutRef.current = true;
    setSecondsLeft(logoutTimeInMinutes * 60);
    setShowWarning(false);
    removeTimers();
  };

  /**
   * Utility function provided as part of context value.
   * Restarts auto logout and reset states.
   */
  const enable = () => {
    if (disableCountRef.current > 0) disableCountRef.current -= 1;
    // only enable when the disable/enable count is even
    if (disableCountRef.current === 0) {
      haltAutoLogoutRef.current = false;
      setSecondsLeft(logoutTimeInMinutes * 60);
      setShowWarning(false);
      resetTimers();
    }
  };

  /**
   * Setup initial event listeners and timers
   */
  useEffect(() => {
    setWarningTimeout();
    events.forEach((evt) => window.addEventListener(evt, resetTimers));
    window.addEventListener(requestStartEventName, disable);
    window.addEventListener(requestEndEventName, enable);

    return () => {
      removeTimers();
      events.forEach((evt) => window.removeEventListener(evt, resetTimers));
      window.removeEventListener(requestStartEventName, disable);
      window.removeEventListener(requestEndEventName, enable);
    };
  }, []);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <>
      {children}
      <Modal open={showWarning} className="autologout-root">
        <Box>
          <Card header={(
            <div className="autologout-header">
              <p className="autologout-title">{t('inactivityWarning.title')}</p>
              <CircledButton onClick={enable}>
                <FaXmark />
              </CircledButton>
            </div>
        )}
          >
            <div className="autologout-content">
              <p>
                {t('inactivityWarning.message', { minutes, seconds })}
              </p>
              <p className="autologout-countdown">
                {minutes}
                :
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
              <Button className="autologout-button" variant="contained" onClick={enable}>
                {t('inactivityWarning.button')}
              </Button>
            </div>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

AutoLogout.defaultProps = defaultProps;
AutoLogout.propTypes = propTypes;

export default AutoLogout;
