// components
import React from 'react';
import { useTranslation } from 'react-i18next';
// styles & assets
import '../styles/components/Header.scss';
import BCMLogo from '../assets/images/BCMLogo.png';
import CooperatorsLogo from '../assets/images/Cooperators_Logo_New.png';

/**
 * Header component
 * @returns {JSX} JSX to render the component
 */

const Header = () => {
  const { t } = useTranslation();
  return (
    <header className="header">
      <img id="cooperators-logo" src={CooperatorsLogo} alt={t('theCooperators')} />
      <img id="bcm-logo" src={BCMLogo} alt={t('BCM')} />
    </header>
  );
};

export default Header;
