import React from 'react';
import PropTypes from 'prop-types';

// styles and assets
import '../styles/components/BlueButton.scss';

/**
 * Blue styled button component - Light blue with white text
 * @param {function} onClick
 * @param {node} text
 * @returns {JSX} JSX to render the component
 */

const BlueButton = ({ onClick, text, disabled }) => (
  <button className="blue-button" type="button" onClick={onClick} disabled={disabled}>{text}</button>
);

BlueButton.defaultProps = {
  onClick: () => {},
  disabled: false,
};

BlueButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default BlueButton;
