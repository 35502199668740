import { createTheme } from '@mui/material/styles';

/**
 * MUI Theme
 */
const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#006EB3',
    },
  },
  typography: {
    // Default font size for all components only accepts a number in px
    // which is something we dont want to use

    fontFamily: '"News Cycle",sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
  },
});

export default muiTheme;
