/**
 * Helper to determine if the language result from useTranslation is set to French
 * @param {object | null} i18n The i18n object
 * @returns {boolean}
 */
function isFrenchSelected(i18n) {
  return i18n && i18n.language && i18n.language.toLowerCase().includes('fr');
}

module.exports = {
  isFrenchSelected,
};
