import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PageAccessContext from '../contexts/PageAccessContext';

const ProtectedRoute = ({ link, children }) => {
  const { pageAccess } = useContext(PageAccessContext);
  const {
    StartEventPage, IncidentManagementTeamPage, VpTeamsPage, CommandCentersPage, DirectoryPage,
    ExternalSupportPage, EmergencyProceduresPage, BCCQuickReferenceDoc, LifeWorksPage,
    SettingsPage, ProfilePage,
  } = pageAccess;
  const routeAccess = [
    {
      link: '/start-event',
      pageAccess: StartEventPage,
    }, {
      link: '/incidents-management-teams',
      pageAccess: IncidentManagementTeamPage,
    }, {
      link: '/my-teams',
      pageAccess: VpTeamsPage,
    }, {
      link: '/command-centers',
      pageAccess: CommandCentersPage,
    }, {
      link: '/directory',
      pageAccess: DirectoryPage,
    }, {
      link: '/external-support',
      pageAccess: ExternalSupportPage,
    }, {
      link: '/emergency-procedures',
      pageAccess: EmergencyProceduresPage,
    }, {
      link: '/bcc-quick-reference',
      pageAccess: BCCQuickReferenceDoc,
    }, {
      link: '/life-works',
      pageAccess: LifeWorksPage,
    }, {
      link: '/settings',
      pageAccess: SettingsPage,
    }, {
      link: '/profile',
      pageAccess: ProfilePage,
    }];
  const navigate = useNavigate();
  useEffect(() => {
    const index = routeAccess.findIndex((route) => route.link === link);
    if (index !== -1 && !routeAccess[index].pageAccess) {
      navigate('/unauthorized');
    }
  });
  return (
    <>
      {children}
    </>
  );
};

ProtectedRoute.defaultProps = {
  link: '',
  children: null,
};
ProtectedRoute.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node,
};

export default ProtectedRoute;
