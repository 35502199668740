import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { faCaretDown, faX, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/components/RecipientsModal.scss';
import { useTranslation } from 'react-i18next';

/**
 * Recipients Modal
 * @returns JSX
 */
const RecipientsModal = ({
  isOpen, event, closeFunctionality, alertId,
}) => {
  const { t } = useTranslation('common');
  const ASCENDING = 'ascending';
  const DESCENDING = 'descending';
  const [sortDirection, setSortDirection] = useState(DESCENDING);
  const [answerSortDirection, setAnswerSortDirection] = useState('');
  const [answerSortInUse, setAnswerSortInUse] = useState(false);

  /**
   * sets sort direction
   */
  const handleSort = (isAnswer) => {
    if (isAnswer) {
      setAnswerSortDirection(answerSortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    }
  };

  /**
   * Checks to display either green or red highlighted text
   * @param {*} response
   * @returns
   */
  const checkClass = (response) => {
    if (response?.responded && response?.status.toLowerCase() === 'not_safe') {
      return 'red';
    }
    if (response?.responded && response?.status.toLowerCase() === 'safe') {
      return 'green';
    }
    return '';
  };

  /**
   * Sorts in reverse alphabetical order
   * @param {*} a comparable one
   * @param {*} b comparable two
   * @returns
   */
  const sortAlpha = (a, b) => {
    if (answerSortInUse) {
      const responseA = a?.response?.status || t('noReply');
      const responseB = b?.response?.status || t('noReply');
      if (responseA < responseB) { return answerSortDirection === ASCENDING ? 1 : -1; }
      if (responseA > responseB) { return answerSortDirection === ASCENDING ? -1 : 1; }
      return 0;
    }
    if (a.name < b.name) { return sortDirection === ASCENDING ? 1 : -1; }
    if (a.name > b.name) { return sortDirection === ASCENDING ? -1 : 1; }
    return 0;
  };

  const recipients = event?.alerts
    .find((al) => al._id.toString() === alertId.toString())?.recipients.sort(sortAlpha);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          zIndex: '999',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          top: '0',
          left: '0',
          width: '100%',
          height: '100vh',
          backdropFilter: 'blur(5px)',
        },
        content: {
          position: 'absolute',
          zIndex: '1000',
          width: '500px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          border: 'none',
          borderRadius: '15px',
          backgroundColor: '#002F6C',
          boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
          height: 'fit-content',
        },
      }}
    >
      <div className="recipients-modal">
        <div className="recipient-header">
          <div className="headers">
            <h1>
              {event.summary}
              {/** Need another tag here to show
               * which question is being answered
               * (not part of MVP) */}
            </h1>
            <h1>
              {event?.alerts?.find((al) => al._id?.toString() === alertId?.toString())?.timestamp}
            </h1>
          </div>
          <button
            type="button"
            onClick={() => {
              closeFunctionality();
            }}
          >
            <div className="inner-border">
              <FontAwesomeIcon icon={faX} />
            </div>
          </button>
        </div>
        <div className="recipient-modal-body-container">
          <div className="recipient-modal-body">
            <button type="button" className="transparent-button" onClick={() => { handleSort(); setAnswerSortInUse(false); }}>
              <div className="recipient-modal-heading" aria-sort={sortDirection === ASCENDING ? ASCENDING : DESCENDING}>
                <FontAwesomeIcon
                  className="cursor"
                  title={t('clickToSort')}
                  icon={sortDirection === ASCENDING ? faCaretUp : faCaretDown}
                />
                <p className="recipient-name-status">{t('name')}</p>
              </div>
            </button>
            <button type="button" className="transparent-button" onClick={() => { handleSort(true); setAnswerSortInUse(true); }}>
              <div className="recipient-modal-heading" aria-sort={answerSortDirection === ASCENDING ? ASCENDING : DESCENDING}>
                <FontAwesomeIcon
                  className="cursor"
                  title={t('clickToSort')}
                  icon={answerSortDirection === ASCENDING ? faCaretUp : faCaretDown}
                />
                <p className="recipient-name-status">{t('reply')}</p>
              </div>
            </button>
          </div>
          <div className="recipient-list">
            {recipients?.sort(sortAlpha)
              .map((recipient) => (
                <div key={recipient.id} className="recipient-details">
                  <p>{recipient.name}</p>
                  <p className={checkClass(recipient.response)}>{recipient.response?.responded ? t(recipient.response.status) : t('noReply')}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

RecipientsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunctionality: PropTypes.func.isRequired,
  event: PropTypes.shape({
    summary: PropTypes.string.isRequired,
    alerts: PropTypes.arrayOf(PropTypes.shape({
      recipients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          response: PropTypes.shape({
            responded: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
          }),
        }),
      ).isRequired,
      timestamp: PropTypes.string.isRequired,
    })),
  }).isRequired,
  alertId: PropTypes.string.isRequired,
};

export default RecipientsModal;
