import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditProfile from './EditProfile';
import Card from './Card';

/** User database card */
const UserDatabaseCard = ({ user, onClose }) => {
  const { t } = useTranslation('common');
  const saveUser = async () => {
  };
  return (
    <div>
      <EditProfile
        user={user}
        header={user._id ? `${user.firstName} ${user.lastName}` : t('newUser')}
        saveUser={saveUser}
        onClose={onClose}
        admin
      />
      <Card header={t('roles')}>
        {user.role?.map((role) => <div key={role.name}>{role.name}</div>) || []}
      </Card>
    </div>
  );
};

UserDatabaseCard.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    emergencyContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relation: PropTypes.string,
      mainPhone: PropTypes.string,
      alternatePhone: PropTypes.string,
    }),
    alternateEmails: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mainPhone: PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    alternatePhones: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    })),
    homeAddress: PropTypes.string,
    workPhone: PropTypes.string,
    extension: PropTypes.string,
    notes: PropTypes.string,
    role: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserDatabaseCard;
