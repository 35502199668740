import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/UserContext';
import EditProfile from '../EditProfile';
import Card from '../Card';

// styles & assets
import '../../styles/components/pages/ProfilePage.scss';
import editBtn from '../../assets/images/profile-page/editBtn.svg';
import formatPhoneNumber from '../../helpers/phone';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { user, updateUser, userLoading } = useContext(UserContext);
  const titleCase = (str) => {
    const splitStr = str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1));
    return splitStr.join(' ');
  };
  // if value exists for variable item, then the item will display
  // if there is a custom value, that will display. if not it will display a base template with item
  const [isEditing, setIsEditing] = useState(false);
  const profileItems = [
    { header: t('firstName'), item: user.firstName },
    { header: t('lastName'), item: user.lastName },
    { header: t('corporateEmail'), item: user.email },
    {
      header: t('alternateEmails'),
      custom: () => (
        <div className="item">
          {user.alternateEmails && user.alternateEmails.map((email) => (
            <p key={email}>{email}</p>
          ))}
        </div>
      ),
      item: user.alternateEmails && user.alternateEmails.length > 0,
    },
    {
      header: t('workPhone'),
      custom: () => (
        <div className="item">
          <p>{user.workPhone && formatPhoneNumber(user.workPhone)}</p>
          {user.extension && (
          <p>
            {t('ext')}
            {user.extension}
          </p>
          )}
        </div>
      ),
      item: user.workPhone,
    },
    {
      header: `${titleCase(t(user.mainPhone.type))} ${titleCase(t('phone'))}`,
      item: user.mainPhone && user.mainPhone.number,
      custom: () => (
        <div className="item">
          <p>{formatPhoneNumber(user.mainPhone.number)}</p>
          {user.mainPhone.extension && (
          <p>
            {t('ext')}
            {user.mainPhone.extension}
          </p>
          )}
        </div>
      ),
    },
    {
      header: t('alternatePhone'),
      custom: () => (
        <div className="item">
          {user.alternatePhones && user.alternatePhones.map((phone) => (
            <div key={phone.number} className="split">
              <p className="first-item">{formatPhoneNumber(phone.number)}</p>
              <p>{titleCase(t(phone.type))}</p>
            </div>
          ))}
        </div>
      ),
      item: user.alternatePhones && user.alternatePhones.length > 0,
    },
    { header: t('homeAddress'), item: user.homeAddress },
    { header: t('notes'), item: user.notes },
  ];
  const emergencyContact = () => (
    <div key={user.emergencyContact.mainPhone} className="emergency-contact" role="list">
      {user.emergencyContact.firstName && (
      <div className="main" role="listitem">
        <h2>{t('firstName')}</h2>
        <p>{user.emergencyContact.firstName}</p>
      </div>
      )}
      {user.emergencyContact.lastName && (
      <div className="main" role="listitem">
        <h2>{t('lastName')}</h2>
        <p>{user.emergencyContact.lastName}</p>
      </div>
      )}
      {user.emergencyContact.relation && (
      <div className="main" role="listitem">
        <h2>{t('relation')}</h2>
        <p>{user.emergencyContact.relation}</p>
      </div>
      )}
      { user.emergencyContact.mainPhone && (
      <div className="phone" role="listitem">
        <h3>{t('mainPhone')}</h3>
        <p>{formatPhoneNumber(user.emergencyContact.mainPhone)}</p>
      </div>
      )}
      {user.emergencyContact.alternatePhone && (
      <div className="phone" role="listitem">
        <h3>{t('alternatePhone')}</h3>
        <p>{formatPhoneNumber(user.emergencyContact.alternatePhone)}</p>
      </div>
      )}

    </div>
  );
  return (
    <div className="profile-page">
      {!isEditing && (
        <Card
          header={(
            <div className="profile-header">
              <button type="button" onClick={() => setIsEditing(true)}>
                <img src={editBtn} alt={t('editButton')} />
              </button>
            </div>
          )}
        >
          {!userLoading && (
            <div className="profile-items-container" role="list">
              {user && profileItems.filter((item) => item.item).map((item) => (
                <div key={item.header} className="item" role="listitem">
                  <h2>{item.header}</h2>
                  {!item.custom && <p>{item.item}</p>}
                  {item.custom && item.custom()}
                </div>
              ))}
              <h1 className="emergency-contact-header">{t('emergencyContact')}</h1>
              {user.emergencyContact && emergencyContact()}
            </div>
          )}
        </Card>
      )}
      {userLoading && <h1 className="loading">{t('loading')}</h1>}

      {isEditing && !userLoading && (
        <EditProfile
          user={user}
          onClose={() => setIsEditing(false)}
          saveUser={updateUser}
        />
      )}
    </div>
  );
};

export default ProfilePage;
