import { useTranslation } from 'react-i18next';
/**
 * templates return for events
 */
export default () => {
  const { t } = useTranslation();
  return ({
    custom: {
      summary: '',
      display: t('custom'),
      payload: {
        notification: {
          title: '',
          body: '',
        },
        data: {
          status: '',
          nextSteps: '',
          question: {
            questionDetails: '',
            yes: [],
            no: [],
          },
        },
      },
    },
    fire: {
      summary: t('fire'),
      display: t('fire'),
      payload: {
        notification: {
          title: t('fire'),
          body: t('fireInProgress'),
        },
        data: {
          status: t('fireAtReportedLocation'),
          nextSteps: '',
          question: {
            questionDetails: '',
            yes: [],
            no: [],
          },
        },
      },
    },
    powerOutage: {
      summary: t('powerOutage'),
      display: t('powerOutage'),
      payload: {
        notification: {
          title: t('powerOutage'),
          body: t('powerOutageInProgress'),
        },
        data: {
          status: t('powerOutageAtReportedLocation'),
          nextSteps: '',
          question: {
            questionDetails: '',
            yes: [],
            no: [],
          },
        },
      },
    },
  });
};
