import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Login from '../Login';

import '../../styles/components/pages/SettingsPage.scss';

const SettingsPage = () => {
  const { t, i18n } = useTranslation();
  const logoutBtnContent = () => (
    <FontAwesomeIcon icon={faRightFromBracket} title={t('logout')} />
  );
  const switchLanguage = (e) => {
    i18n.changeLanguage(
      e.target.value === 'en' ? 'en' : 'fr',
    );
  };

  return (
    <div className="settings">
      <div className="settings-header" />
      <div className="content">
        <h2>{t('language')}</h2>
        <select name="language" id="language" className="selector-input" onChange={(e) => switchLanguage(e)}>
          <option value="en">{t('english')}</option>
          <option value="fr">{t('french')}</option>
        </select>
        <Login logoutProp logoutBtnContent={logoutBtnContent} logoutName={t('logout')} />
      </div>
    </div>
  );
};

export default SettingsPage;
