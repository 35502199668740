import React, {
  useEffect, useState, useContext, useReducer,
} from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';
// import { AiOutlineUserAdd } from 'react-icons/ai';
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import Role from '../../enums/Role';
import updateToast from '../../services/toastService';
import axios from '../../services/Api';
import DirectoryItemMulti from '../DirectoryItem';
import DirectoryFilterSelect from '../DirectoryFilterSelect';
import SearchBar from '../SearchBar';
import MultiSelectDisplay from '../MultiSelectDisplay';
import BlueButton from '../BlueButton';
import Card from '../Card';
import { getDirectory, getDirectoryRest } from '../../hooks/DirectoryHook';
import downloadStyledExcelSheet from '../../hooks/downloadStyledExcelSheet';
import PageAccessContext from '../../contexts/PageAccessContext';
import UserContext from '../../contexts/UserContext';
import ContactCard from '../ContactCard';
import UserDatabaseCard from '../UserDatabaseCard';

import '../../styles/components/pages/DirectoryPage.scss';

/** Directory Page */
const DirectoryPage = ({ admin }) => {
  const { t } = useTranslation();
  const { pageAccess } = useContext(PageAccessContext);
  const { user: currentUser } = useContext(UserContext);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [allNone, setAllNone] = useState(true);
  const [filterRoleModalOpen, setFilterRoleModalOpen] = useState(false);
  const [contactCardInfo, setContactCardInfo] = useState(null);
  const [filterLocationModalOpen, setFilterLocationModalOpen] = useState(false);
  const roles = [
    { value: Role.BusinessContinuityCoordinator, display: t('businessContinuityCoordinator') },
    { value: Role.BusinessTeam, display: t('businessTeam') },
    { value: Role.CommunicationsTeam, display: t('communicationsTeam') },
    { value: Role.DecisionMaker, display: t('decisionMaker') },
    { value: Role.Executive, display: t('executive') },
    { value: Role.InfrastructureTeam, display: t('infrastructureTeam') },
    { value: Role.IncidentManagementTeamSupport, display: t('imtSupport') },
    { value: Role.LogisticsTeam, display: t('logistics') },
    { value: Role.OfficeLead, display: t('officeLead') },
    { value: Role.OperationsTeam, display: t('operations') },
    { value: Role.VicePresident, display: t('vicePresident') },
    { value: Role.AssociateVicePresident, display: t('assistantVicePresident') },
    { value: Role.CGLBusinessContinuityCoordinator, display: t('cglBcc') },
    { value: Role.Scribe, display: t('scribe') },
  ];
  const [locations, setLocations] = useState([]);
  const [check, setCheck] = useState([]);
  const [page, setPage] = useState([]);
  const [nextPage, setNextPage] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [showSpecialCharErr, setShowSpecialCharErr] = useState(false);

  const [filters, updateFilters] = useReducer(
    (state, action) => {
      const updates = {};
      if (action.location) {
        updates.location = locations.filter((_, i) => action.location[i]);
      }
      if (action.role) {
        updates.role = roles.filter((_, i) => action.role[i]).map((role) => role.value);
      }
      if (action.name !== undefined) {
        updates.name = action.name;
      }
      if (action.isAdminFilter !== undefined) {
        updates.isAdminFilter = action.isAdminFilter;
      }
      return {
        ...state,
        ...updates,
      };
    },
    {
      role: [],
      location: [],
      name: '',
      isAdminFilter: false,
    },
  );

  /** Whether or not the current user is an admin */
  const isAdmin = admin || currentUser?.isAdmin;

  const handleFetchDirectory = async (abortSignal) => {
    setAllNone(true);
    const data = await Promise.all([
      getDirectory(0, filters, pageAccess.DirectoryService, abortSignal),
      getDirectory(1, filters, pageAccess.DirectoryService, abortSignal),
    ]);
    setCheck(new Array(data[0] ? data[0].length : 0).fill(true));
    setPage(data[0]);
    setNextPage(data[1]);
    setPageIndex(1);
  };

  const handleExport = async (e) => {
    e.preventDefault();
    setLoadingExport(true);
    const pageFiltered = page.filter((_, i) => check[i]);
    if (pageFiltered.length === 0 && (allNone === false || nextPage.length === 0)) {
      toast(t('pleaseSelectContact'), { type: 'error' });
      setLoadingExport(false);
      return;
    }
    const toastId = toast.loading(t('downloadInProgress'));
    try {
      let final = pageFiltered;
      if (allNone === true) {
        const data = await getDirectoryRest(pageIndex, filters, pageAccess.DirectoryService);
        final = [...final, ...data];
      }
      const headers = [
        t('firstName'),
        t('lastName'),
        t('location'),
        t('corporateEmail'),
        `${t('alternateEmail')} 1`,
        `${t('alternateEmail')} 2`,
        t('workPhone'),
        `${t('mainPhone')}`,
        '',
        `${t('alternatePhone')} 1`,
        '',
        `${t('alternatePhone')} 2`,
        '',
        `${t('emergencyContact')}`,
        '',
        '',
        '',
        '',

      ];
      const subHeaders = [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        t('type'),
        t('number'),
        t('type'),
        t('number'),
        t('type'),
        t('number'),
        t('firstName'),
        t('lastName'),
        t('mainPhone'),
        t('alternatePhone'),
      ];
      const rows = final.map((user) => [user.firstName, user.lastName, user.location,
        user.email, user.alternateEmails?.[0],
        user.alternateEmails?.[1], user.workPhone,
        user.mainPhone?.type, user.mainPhone?.number,
        user.alternatePhones?.[0]?.type,
        user.alternatePhones?.[0]?.number,
        user.alternatePhones?.[1]?.type,
        user.alternatePhones?.[1]?.number,
        user.emergencyContact?.firstName,
        user.emergencyContact?.lastName,
        user.emergencyContact?.relation,
        user.emergencyContact?.mainPhone,
        user.emergencyContact?.alternatePhone,
      ]);
      await downloadStyledExcelSheet(rows, headers, subHeaders, t('users'), t('users'));
      updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
    } catch (err) {
      updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
    } finally {
      setLoadingExport(false);
    }
  };

  const handleFetchLocations = async (abortSignal) => {
    const { data } = await axios.get('/constants/locations', { signal: abortSignal });
    const locationsTemp = data.map((location) => location.name);
    setLocations(locationsTemp);
  };

  const handleCheck = (i) => {
    const temp = [...check];
    temp[i] = !temp[i];
    setCheck(temp);
  };

  const handleSelectAllNone = () => {
    const temp = [...check];
    let final;
    if (!allNone) {
      final = temp.map(() => true);
    } else {
      final = temp.map(() => false);
    }
    setCheck(final);
    setAllNone(!allNone);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const loadDirectory = async () => {
      try {
        await Promise.all([
          handleFetchLocations(abortController.signal),
          handleFetchDirectory(abortController.signal),
        ]);
        setIsPageLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingDirectory'));
      }
    };
    loadDirectory();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // Don't filter if page is initially loading (no filters have been set)
    if (isPageLoading) return () => {};
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        await handleFetchDirectory(abortController.signal);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorFilteringDirectory'));
      }
    };
    if (!showSpecialCharErr) {
      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, [filters]);

  useEffect(() => {
    const specialChars = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
    setShowSpecialCharErr(specialChars.test(search));
  }, [search]);

  useEffect(() => {
    // Don't filter if page is initially loading (no filters have been set by the user)
    if (isPageLoading && !search) return () => {};
    const timeout = setTimeout(() => {
      updateFilters({ name: search });
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const fetchNextPage = async () => {
    if (nextPage.length > 0) {
      try {
        const data = await getDirectory(pageIndex + 1, filters, pageAccess.DirectoryService);
        const newPage = [...page, ...nextPage];
        setPage(newPage.filter((item, index) => newPage.findIndex(
          (indexItem) => indexItem._id === item._id,
        ) === index));
        setNextPage(data);
        setCheck([...check, ...new Array(nextPage.length).fill(allNone)]);
        setPageIndex(pageIndex + 1);
      } catch {
        toast.error(t('errorLoadingDirectory'));
      }
    }
  };

  const updateProfileReminder = () => {
    // TODO: Complete profile reminder functionallity (BCAP-26)
  };

  // Unreleased functionality
  // const addUser = () => {
  //   setContactCardInfo({
  //     _id: '',
  //   });
  // };

  return (
    <div className="directory-main">
      {isAdmin && !contactCardInfo && (
        <div className="profile-update-button-container">
          <BlueButton text={t('sendProfileUpdateReminder')} onClick={updateProfileReminder} />
        </div>
      )}
      {!contactCardInfo
      && !filterRoleModalOpen && !filterLocationModalOpen && (
      <>
        <Card
          className="directory-main-card"
          header={(
            <div className="directory-main-header">
              <h2>{t('filters')}</h2>
            </div>
          )}
        >
          <div className="filters-container">
            <SearchBar
              placeholder={t('search')}
              label={t(admin ? 'userDatabaseSearch' : 'directorySearch')}
              id="directorySearch"
              type="text"
              value={search}
              disabled={false}
              onChange={setSearch}
            />
            {showSpecialCharErr && <p className="error-text">{t('noSpecialChars')}</p>}
            <MultiSelectDisplay
              options={locations}
              isSelectedMulti={locations.map(
                (option) => filters.location.findIndex((o) => o === option) !== -1,
              )}
              setIsSelected={(l) => updateFilters({ location: l })}
              setIsOpen={setFilterLocationModalOpen}
              name={t('locations')}
              isMulti
            />
            <MultiSelectDisplay
              options={roles.map((role) => role.display)}
              isSelectedMulti={roles.map(
                (option) => filters.role.findIndex((o) => o === option.value) !== -1,
              )}
              setIsSelected={(r) => updateFilters({ role: r })}
              setIsOpen={setFilterRoleModalOpen}
              name={t('role')}
              isMulti
            />
            {isAdmin && (
              <div className="admin-portal-filter-container">
                <button
                  className="admin-portal-filter"
                  type="button"
                  aria-label={t('adminPortalAdministratorsFilter')}
                  onClick={() => updateFilters({ isAdminFilter: !filters.isAdminFilter })}
                  aria-pressed={filters.isAdminFilter}
                >
                  <div className="button-container">
                    <div className="button-text">{t('adminPortalAdministrators')}</div>
                    <div className="button-icon">{filters.isAdminFilter ? <FaRegCheckCircle /> : <FaRegCircle />}</div>
                  </div>
                </button>
              </div>
            )}
            <div className="cancel-button">
              <BlueButton
                text={t('clearAll')}
                onClick={() => {
                  updateFilters({
                    role: [],
                    location: [],
                    name: '',
                    isAdminFilter: false,
                  });
                  setSearch('');
                }}
              />
            </div>
          </div>
        </Card>
        <Card
          className="directory-main-card"
          header={(
            <div className="directory-main-header">
              <h2>{t(admin ? 'users' : 'contacts')}</h2>
              <div className="excel-container">
                <IconContext.Provider value={{ size: '1.5em' }}>
                  <button type="button" onClick={(e) => (handleExport(e))} disabled={loadingExport}>
                    <span>{t(loadingExport ? 'loading' : 'export')}</span>
                    <BsFileEarmarkExcelFill aria-label={t('exportToExcel')} />
                  </button>
                  {/*
                    Unreleased functionality, commenting out
                    {isAdmin && (
                      <button type="button" onClick={addUser}>
                        <AiOutlineUserAdd title={t('addUser')} />
                        <span>{t('add')}</span>
                      </button>
                    )} */}
                </IconContext.Provider>
              </div>
            </div>
          )}
        >
          <div className="items-container">
            <InfiniteScroll
              dataLength={page.length}
              next={() => fetchNextPage()}
              hasMore={nextPage?.length > 0}
            >
              <DirectoryItemMulti
                check={allNone}
                setCheck={handleSelectAllNone}
                header={t('selectAllNone')}
              />
              {page.map((contact, i) => (
                <DirectoryItemMulti
                  key={contact._id}
                  check={check[i]}
                  setCheck={() => handleCheck(i)}
                  header={`${contact.firstName} ${contact.lastName}`}
                  phone={contact.workPhone}
                  link={`/directory/${contact._id}`}
                  handleCard={() => setContactCardInfo(contact)}
                  isArrow
                />
              ))}
            </InfiniteScroll>
          </div>
        </Card>
      </>
      )}
      {filterRoleModalOpen && (
      <DirectoryFilterSelect
        options={roles}
        selectedOptions={filters.role}
        setSelected={(r) => updateFilters({ role: r })}
        name="roles"
        setOpen={setFilterRoleModalOpen}
        isMulti
      />
      )}
      {filterLocationModalOpen && (
      <DirectoryFilterSelect
        options={locations}
        selectedOptions={filters.location}
        setSelected={(l) => updateFilters({ location: l })}
        name="locations"
        setOpen={setFilterLocationModalOpen}
        isMulti
      />
      )}
      {contactCardInfo && !admin && (
        <ContactCard user={contactCardInfo} handleClose={() => setContactCardInfo(null)} />
      )}
      {contactCardInfo && admin && (
        <UserDatabaseCard user={contactCardInfo} onClose={() => setContactCardInfo(null)} />
      )}
    </div>
  );
};

DirectoryPage.defaultProps = {
  admin: false,
};

DirectoryPage.propTypes = {
  admin: propTypes.bool,
};

export default DirectoryPage;
