import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/KDCLogo.scss';

/**
 * Component for the Spinning KDC Logo
 */
const KDCLogo = ({ spinning = false }) => (
  <svg
    className="kdc-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="261.06"
    height="312"
    viewBox="0 0 261.06 312"
  >
    <title>KDC_Logo_New_Icon</title>
    <path className={spinning ? 'kdc-square-1' : ''} d="M124.75,228.12,24,127.33,124.75,26.55,225.54,127.33ZM31,127.33l93.71,93.72,93.71-93.72L124.75,33.62Z" fill="#006eb3" />
    <path className={spinning ? 'kdc-square-2' : ''} d="M124.75,255.1,24,154.31,124.75,53.53,225.54,154.31ZM31,154.31,124.75,248l93.71-93.72L124.75,60.6Z" fill="#006eb3" />
    <path className={spinning ? 'kdc-square-3' : ''} d="M124.75,282.1,24,181.31,124.75,80.53,225.54,181.31ZM31,181.31,124.75,275l93.71-93.72L124.75,87.6Z" fill="#006eb3" />
    <path className={spinning ? 'kdc-icon-3' : ''} d="M148.13,155.32l-34-34c6.07-6.5,13.45-6.93,19.53-1.1,7.32,7,14.39,14.3,21.57,21.47L169,155.46c-2.88,2.78-5.54,5.26-8.11,7.83-8.84,8.8-17.59,17.69-26.49,26.42-6.17,6-13.83,6-20.28-.4Z" fill="#006eb3" />
    <path className={spinning ? 'kdc-icon-2' : ''} d="M136.07,155.24c-8.12,8.21-15.87,16.19-23.82,24-2.66,2.61-6.64,2.42-9.55.06-1.92-1.56-3.75-3.24-5.94-5.14l18.87-18.92-18.81-19c2.05-1.79,3.77-3.37,5.56-4.85,3.31-2.73,6.93-3.06,9.93-.14C120.27,139,128,147,136.07,155.24Z" fill="#006eb3" />
    <path className={spinning ? 'kdc-icon-1' : ''} d="M92.19,143.18,103.89,155c-3.85,3.76-7.94,7.74-12.14,11.85C81.09,159.47,81.65,150.84,92.19,143.18Z" fill="#006eb3" />
  </svg>
);
KDCLogo.defaultProps = {
  spinning: false,
};

KDCLogo.propTypes = {
  spinning: PropTypes.bool,
};

export default KDCLogo;
