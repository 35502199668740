import React from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';

import NavMenu from './NavMenu';

import '../styles/components/HamburgerMenu.scss';

/**
 * Hamburger menu component
 * @param {onClick} function
 * @returns {JSX} JSX to render the component
 */

const HamburgerMenu = ({ onClick }) => (
  <Menu
    className="hamburger-menu"
    noTransition
    disableCloseOnEsc
    isOpen
    styles={{
      bmOverlay: {
        background: 'rgba(0,0,0,0.4)',
        zIndex: '988',
      },
      bmItemList: {
        width: '100%',
      },
      bmMenu: {
        width: 'fit-content',
      },
    }}
    onStateChange={() => onClick()}
  >
    <div className="hamburger-container" data-testid="hamburger-menu">
      <NavMenu onClick={onClick} forComponent="HamburgerMenu" />
    </div>
  </Menu>
);

HamburgerMenu.defaultProps = {
  onClick: () => {

  },
};
HamburgerMenu.propTypes = {
  onClick: PropTypes.func,
};

export default HamburgerMenu;
