import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import updateToast from '../../services/toastService';

import PageSvg from '../../assets/bcc-quick-reference/page.svg';
import '../../styles/components/pages/BCCQuickReference.scss';
import axios from '../../services/Api';
import downloadDocument from '../../hooks/downloadDocumentHook';

const downloadButton = (text, t, setIsDownloadLoading, isDownloadLoading, index) => {
  const handleLoad = (state) => {
    const temp = [...isDownloadLoading];
    temp[index] = state;
    setIsDownloadLoading(temp);
  };
  const handleDownload = async (location) => {
    const toastId = toast.loading(t('downloadInProgress'));
    handleLoad(true);
    try {
      handleLoad(true);
      await downloadDocument(`/constants/document/bcc-quick-reference${location}`, location);
      updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
    } catch (err) {
      updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
    } finally {
      handleLoad(false);
    }
  };
  return (
    <button type="button" className="download-button" onClick={() => handleDownload(text)} key={text} disabled={isDownloadLoading[index]}>
      {!isDownloadLoading[index] && <h2>{text}</h2>}
      {isDownloadLoading[index] && <h2>{t('loading')}</h2>}
      <div className="icons">
        <img src={PageSvg} alt={t('downloadPdf')} />
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </button>
  );
};

const BCCQuickReferencePage = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState([]);
  const getLocations = async () => {
    setLoading(true);
    const { data } = await axios.get('/constants/bcc-documents');
    setIsDownloadLoading(Array(data.length).fill());
    setLoading(false);
    setLocations(data);
  };
  useEffect(() => {
    getLocations();
  }, []);
  const { t } = useTranslation('common');
  return (
    <div className="bcc-quick-reference">
      <div className="bcc-header" />
      {locations.length > 0 && !loading
      && (
      <div className="links-container">
        {locations && locations.filter((location) => location !== null)
          .map((location, index) => downloadButton(
            location, t, setIsDownloadLoading, isDownloadLoading, index,
          ))}
      </div>
      )}
      {locations.length === 0 && !loading
        && <p>{t('noBccDocsFromLocations')}</p>}
      {loading
      && <p>{t('loading')}</p>}

    </div>
  );
};

export default BCCQuickReferencePage;
