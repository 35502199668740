import React, { useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PageAccessContext from '../contexts/PageAccessContext';
import UserContext from '../contexts/UserContext';

import Navbar from './Navbar';
import HamburgerMenu from './HamburgerMenu';
import Footer from './Footer';
import Header from './Header';

import '../styles/components/PageContainer.scss';

/**
 * PageContainer component class
 * Includes Header, Footer, and anything else that may be consistent across the app
 */

const PageContainer = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const { pageAccess, pageAccessLoading } = useContext(PageAccessContext);
  const { t } = useTranslation();
  const { userLoading } = useContext(UserContext);

  const closeHamburger = () => {
    setHamburgerOpen(false);
  };

  return (
    <>
      {hamburgerOpen && (
        <HamburgerMenu
          onClick={closeHamburger}
        />
      )}
      <Header />
      <ToastContainer
        style={{ zIndex: 999999 }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {(pageAccessLoading || userLoading) && <h1>{t('loading')}</h1>}
      <div className="page-container" style={{ minHeight: hamburgerOpen ? 'max(95vh, 1200px)' : '95vh' }}>
        {!pageAccessLoading && !userLoading && (
          <>
            <div>
              <Navbar
                setIsMenuOpen={setHamburgerOpen}
                isMenuOpen={hamburgerOpen}
                pageAccess={pageAccess}
              />
            </div>
            <div className="page-content">
              <Outlet />
            </div>
          </>
        )}
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PageContainer;
