import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// styles from '../../styles/components/pages/PageNotFound.scss'; imported elsewhere

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="error-page">
      <h1>{t('pageNotFound')}</h1>
      <h2>{t('pageNotFoundDescription')}</h2>
      <Link to="/">{t('clickToGoHome')}</Link>
    </div>
  );
};

export default PageNotFound;
