import axios from '../services/Api';

/**
 *  * decodes and downloads pdf files
 * */

const downloadDocument = async (link, downloadName) => {
  const { data } = await axios.get(link);
  const byteChars = atob(data.file);
  const byteNumbers = [];
  for (let i = 0; i < byteChars.length; i += 1) {
    byteNumbers.push(byteChars.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  const tag = document.createElement('a');
  tag.href = window.URL.createObjectURL(blob);
  tag.id = 'downloadAnchor';
  tag.download = `${downloadName}.pdf`;
  tag.click();
  window.URL.revokeObjectURL(tag.href);
};

/**
 * Downloads document through a buffer
 */

export const downloadDocumentBuffer = async (buffer, downloadName, type) => {
  const blob = new Blob([buffer], { type });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = downloadName;
  anchor.click();
  window.URL.revokeObjectURL(url);
};

export default downloadDocument;
