import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { LuChevronDown, LuChevronUp } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import DirectoryItem from './DirectoryItem';

import '../styles/components/MultiSelectDropDownStartEvent.scss';

function MultiSelectDropdownStartEvent({
  header, items, handleSelect, handleSelectAll, isAllSelected, invertExpanded,
}) {
  const contentHeight = useRef();
  const { t } = useTranslation();
  return (
    <div className="multi-select-dropdown-start-event">
      <div
        className="collapsible-row"
      >
        <DirectoryItem
          header={t(header)}
          check={isAllSelected.selectAll}
          setCheck={() => handleSelectAll(header)}
        />
        <button
          className="expand-button"
          tabIndex="0"
          type="button"
          onKeyDown={() => invertExpanded(header)}
          onClick={() => invertExpanded(header)}
        >
          {isAllSelected.isExpanded ? <LuChevronUp size="20px" className="arrow" title={`${t('close')} ${t(header)}`} /> : <LuChevronDown size="20px" className="arrow" title={`${t('open')} ${t(header)}`} />}
        </button>
      </div>
      <div
        ref={contentHeight}
        className="select-contents"
        style={isAllSelected.isExpanded
          ? { height: contentHeight?.current?.scrollHeight, display: 'inline' }
          : { height: '0px', visibility: 'hidden', display: 'none' }}
      >
        {items.sort((a, b) => a.name > b.name).map((item) => (
          <div key={`${item._id} ${item.location}  ${item.role}`}>
            <DirectoryItem header={`${item.name}`} check={item.select} setCheck={() => handleSelect(header, item._id)} />
          </div>
        ))}
      </div>
    </div>
  );
}

MultiSelectDropdownStartEvent.defaultProps = {
  header: '',
  items: [],
  handleSelect: () => { },
  handleSelectAll: () => { },
  isAllSelected: false,
  isExpanded: false,
  invertExpanded: () => { },
};

MultiSelectDropdownStartEvent.propTypes = {
  header: PropTypes.string,
  handleSelect: PropTypes.func,
  handleSelectAll: PropTypes.func,
  isAllSelected: PropTypes.shape({
    isExpanded: PropTypes.bool,
    selectAll: PropTypes.bool,
  }),
  isExpanded: PropTypes.bool,
  invertExpanded: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    location: PropTypes.string,
    role: PropTypes.string,
    name: PropTypes.string,
    select: PropTypes.bool,
  })),
};

export default MultiSelectDropdownStartEvent;
