import React from 'react';
import PropTypes from 'prop-types';
import IconColours from './IconColours';

/** BCC Quick Reference Icon */
const BCCQuickReferenceIcon = ({
  width, height, title, className, color,
}) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <title>{title}</title>
    <path
      d="M13.3333 10.6666V26.7333V26.6666V53.3333V10.6666ZM18.6667 37.3333H28C28.4 36.3111 28.8889 35.3555 29.4667 34.4666C30.0444 33.5778 30.7111 32.7555 31.4667 32H18.6667V37.3333ZM18.6667 48H27.1333C26.9111 47.1111 26.7671 46.2222 26.7013 45.3333C26.6356 44.4444 26.6462 43.5555 26.7333 42.6666H18.6667V48ZM8 58.6666V5.33331H34.6667L50.6667 21.3333V28C49.8222 27.6444 48.9556 27.3671 48.0667 27.168C47.1778 26.9689 46.2667 26.824 45.3333 26.7333V24H32V10.6666H13.3333V53.3333H29.4C30.1111 54.4 30.9111 55.3893 31.8 56.3013C32.6889 57.2133 33.6667 58.0018 34.7333 58.6666H8ZM44 50.6666C45.8667 50.6666 47.4444 50.0222 48.7333 48.7333C50.0222 47.4444 50.6667 45.8666 50.6667 44C50.6667 42.1333 50.0222 40.5555 48.7333 39.2666C47.4444 37.9778 45.8667 37.3333 44 37.3333C42.1333 37.3333 40.5556 37.9778 39.2667 39.2666C37.9778 40.5555 37.3333 42.1333 37.3333 44C37.3333 45.8666 37.9778 47.4444 39.2667 48.7333C40.5556 50.0222 42.1333 50.6666 44 50.6666ZM57.6 61.3333L50.4 54.1333C49.4667 54.7555 48.456 55.2222 47.368 55.5333C46.28 55.8444 45.1573 56 44 56C40.6667 56 37.8338 54.8338 35.5013 52.5013C33.1689 50.1689 32.0018 47.3351 32 44C32 40.6666 33.1671 37.8338 35.5013 35.5013C37.8356 33.1689 40.6684 32.0018 44 32C47.3333 32 50.1671 33.1671 52.5013 35.5013C54.8356 37.8355 56.0018 40.6684 56 44C56 45.1555 55.8444 46.2782 55.5333 47.368C55.2222 48.4578 54.7556 49.4684 54.1333 50.4L61.3333 57.6L57.6 61.3333Z"
      fill={color}
    />
  </svg>
);

BCCQuickReferenceIcon.defaultProps = {
  width: 64,
  height: 64,
  title: '',
  className: undefined,
  color: IconColours.WHITE,
};

BCCQuickReferenceIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default BCCQuickReferenceIcon;
