import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import Row from './Row';

/** Draggable Row component */
const DraggableRow = ({ id, ...props }) => {
  const { t } = useTranslation('common');
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id, attributes: { roleDescription: t('sortable.roleDescription') } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Row
      ref={setNodeRef}
      style={style}
      // Pass remaining props to the underlying Row component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      dnd={{ ...attributes, ...listeners, ref: setActivatorNodeRef }}
      draggable
    />
  );
};

DraggableRow.propTypes = {
  ...Row.propTypes,
  id: PropTypes.string.isRequired,
};
DraggableRow.defaultProps = Row.defaultProps;

export default DraggableRow;
