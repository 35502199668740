import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { LuChevronDownCircle, LuChevronUpCircle } from 'react-icons/lu';
import '../styles/components/CollapsibleRow.scss';

/**
 * CollapsibleRow component
 * @param {header} element
 * @param {children} element
 * @param {isExpanded} boolean
 * @param {invertExpanded} function
 * @param {rowIndex} number
 * @returns {JSX} JSX to render the component
 */

const CollapsibleRow = ({
  header, children, isExpanded, invertExpanded, rowIndex,
}) => {
  const contentHeight = useRef();

  return (
    <div className="wrapper">
      <div
        className="collapsible-row"
        tabIndex="0"
        role="button"
        onKeyDown={() => invertExpanded(rowIndex)}
        onClick={() => invertExpanded(rowIndex)}
      >
        <div className="row-header">
          {header}
        </div>
        {isExpanded ? <LuChevronUpCircle size="20px" className="arrow" /> : <LuChevronDownCircle size="20px" className="arrow" />}
      </div>

      <div
        ref={contentHeight}
        className="row-container"
        style={
          isExpanded
            ? { }
            : { height: '0px', visibility: 'hidden' }
         }
      >
        { children }

      </div>
    </div>
  );
};

CollapsibleRow.defaultProps = {
  isExpanded: false,
};

CollapsibleRow.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  invertExpanded: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
};

export default CollapsibleRow;
