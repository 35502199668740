import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/CircledButton.scss';

const CircledButton = ({ onClick, children, label }) => (
  <button
    className="circled-button"
    type="button"
    onClick={(e) => onClick(e)}
    aria-label={label}
  >
    <div className="inner-border">
      {children}
    </div>
  </button>
);

CircledButton.defaultProps = {
  onClick: () => { },
  label: '',
};

CircledButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

export default CircledButton;
