import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SiGooglemaps } from 'react-icons/si';
import { FiPhone } from 'react-icons/fi';
import sanitizeHtml from 'sanitize-html';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import Card from '../Card';
import StyledSelect from '../StyledSelect';
import { getExternalSupport, getExternalSupportLocations } from '../../hooks/ExternalSupportHooks';
import '../../styles/components/pages/ExternalSupportPage.scss';
import CollapsibleRow from '../CollapsibleRow';
import BlueButton from '../BlueButton';
import formatPhoneNumber from '../../helpers/phone';

const ExternalSupportPage = () => {
  const { t } = useTranslation();
  const [location, setLocation] = useState({ value: '', label: t('selectALocation') });
  const [locations, setLocations] = useState('');
  const [isExpanded, setIsExpanded] = useState([]);
  const [noLocations, setNoLocations] = useState('');
  const [page, setPage] = useState([]);
  const [nextPage, setNextPage] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const handleFetch = async (signal) => {
    // get all external support
    try {
      const data = await getExternalSupportLocations(signal);
      const formatted = data.map((locationTemp) => ({
        value: locationTemp,
        label: locationTemp,
      }));
      setLocations(formatted);
      if (formatted.length === 0) {
        setNoLocations(t('noLocationsHaveExternalSupport'));
      }
    } catch (err) {
      if (err.name === 'CanceledError') {
        return;
      }
      toast(t('couldNotRetrieveExternalSupport'), { type: 'error' });
    }
  };
  useEffect(() => {
    const abortController = new AbortController();
    handleFetch(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  const handleRowExpandedChange = (index) => {
    const temp = [...isExpanded];
    temp[index] = !temp[index];
    setIsExpanded(temp);
  };
  const allExpanded = () => {
    if (isExpanded.length === 0) {
      return false;
    }
    return isExpanded.every((expansion) => expansion !== true);
  };

  const invertAllExpanded = () => {
    const allAreExpanded = allExpanded();
    const newExpandedRows = [...isExpanded];
    const final = newExpandedRows.map(() => allAreExpanded);
    setIsExpanded(final);
  };

  // Get Location data (command centers) for Location Name
  useEffect(() => {
    const abortController = new AbortController();

    const getLocationData = async (i, signal) => {
      // Get only location names for dropdown
      try {
        const data = await Promise.all(
          [getExternalSupport(i, location.value, signal),
            getExternalSupport(i + 1, location.value, signal)],
        );
        setIsExpanded(new Array(data[0].length).fill(false));
        setNextPage(data[1]);
        setPage(data[0]);
        setPageIndex(pageIndex + 1);
      } catch (err) {
        if (err.name === 'CanceledError') {
          return;
        }
        toast(t('couldNotRetrieveLocations'), { type: 'error' });
      }
    };
    if (location) {
      getLocationData(0, abortController.signal);
    }

    return () => {
      abortController.abort();
    };
  }, [location]);
  const fetchNextPage = async (i) => {
    if (nextPage.length > 0) {
      try {
        const data = await getExternalSupport(i + 1, location.value);
        setPage([...page, ...nextPage]);
        setNextPage(data);
        setIsExpanded([...isExpanded, ...new Array(nextPage.length).fill(false)]);
        setPageIndex(pageIndex + 1);
      } catch (err) {
        toast(t('couldNotRetrieveExternalSupportNextPage'), { type: 'error' });
      }
    }
  };
  const checkIfItemExists = (condition, children) => {
    if (condition) {
      return (children);
    }

    return (<p className="dark-blue-text">{t('notApplicable')}</p>);
  };
  return (

    <div className="external-support">
      {locations && (
        <>
          {!noLocations && (
          <Card header={t('location')}>
            <div className="select-container">
              <StyledSelect value={location} onChange={setLocation} options={locations} />
            </div>
          </Card>
          )}
          {noLocations && (
            <Card header={t('noLocations')}>
              <div className="no-locations">
                <h1>{noLocations}</h1>
              </div>
            </Card>
          )}

            {location.value !== '' && page.length > 0
            && (
              <Card header={location.label}>
                <InfiniteScroll
                  dataLength={pageIndex * 25}
                  next={() => fetchNextPage(pageIndex)}
                  hasMore={nextPage.length > 0}
                >
                  <div className="button-container">
                    <BlueButton onClick={() => invertAllExpanded()} text={allExpanded() ? t('expandAll') : t('collapseAll')} />
                  </div>
                  {page.map((item, index) => (
                    <CollapsibleRow
                      key={item._id}
                      header={<p className="dark-blue-text">{item.name}</p>}
                      isExpanded={isExpanded[index]}
                      invertExpanded={handleRowExpandedChange}
                      rowIndex={index}
                    >
                      <h3 className="medium-text">{t('contact')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.contact, <p className="dark-blue-text">{item.contact}</p>)}
                      </div>
                      <h3 className="medium-text">{t('accountNumber')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.contact, <p className="dark-blue-text">{item.accountNumber}</p>)}
                      </div>
                      <h3 className="medium-text">{t('phoneNumber')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.phoneNumber,
                          <a
                            className="dark-blue-text"
                            aria-label={`${t('call', { number: item.phoneNumber })}`}
                            href={sanitizeHtml(`tel:${item.number}`)}
                          >
                            <p className="dark-blue-text">{item.phoneNumber && formatPhoneNumber(item.phoneNumber)}</p>
                            <FiPhone className="large-icon" />
                          </a>)}
                      </div>

                      <h3 className="medium-text">{t('address')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.address,
                          <a
                            className="dark-blue-text"
                            aria-label={`${t('openMapsFor', { location: item.address })}`}
                            target="_blank"
                            href={sanitizeHtml(`https://maps.google.com/?q=${item.address}`)}
                            rel="noreferrer"
                          >
                            <p className="dark-blue-text">{item.address}</p>
                            <SiGooglemaps className="large-icon" />
                          </a>)}
                      </div>
                      <h3 className="medium-text">{t('type')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.type, <p className="dark-blue-text">{item.type}</p>)}
                      </div>
                    </CollapsibleRow>
                  ))}
                </InfiniteScroll>
              </Card>
            )}

        </>
      )}

    </div>
  );
};

export default ExternalSupportPage;
