import React from 'react';
import { FiXCircle } from 'react-icons/fi';
import { IoMdArrowDropdown } from 'react-icons/io';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../styles/components/MultiSelectDisplay.scss';

const MultiSelectDisplay = ({
  options, setIsSelected, isSelectedMulti, setIsOpen, name, isMulti, isSelected,
  placeholder,
}) => {
  const { t } = useTranslation();
  const handleClose = (e, i) => {
    e.preventDefault();
    const temp = [...isSelectedMulti];
    temp[i] = !temp[i];
    setIsSelected(temp);
  };

  const multiStyle = (multi) => {
    if (multi && isSelectedMulti.includes(true)) {
      return {
        paddingLeft: isSelectedMulti.filter((selected) => selected).length === 0 ? '99%' : 'none',
      };
    }
    return {
      flexGrow: '1',
      justifyContent: 'space-between',
      margin: '0',
    };
  };

  return (
    <div className="multi-select-display">
      {name
      && (
      <h1 className="select-display-header">
        {name}
      </h1>
      )}
      {isMulti && isSelectedMulti.includes(false)
      && (
      <div className="selection-container-multi">
        {options.map((option, i) => (
          isSelectedMulti[i]
        && (
        <div key={option} className="selection">
          {option}
          <button type="button" onClick={(e) => handleClose(e, i)} className="cancel-item-button" aria-label={`${option} ${t('button')}`}>
            <FiXCircle className="button-icon" />
          </button>
        </div>
        )
        ))}
      </div>
      )}
      <button
        className={!isSelectedMulti.includes(false) ? 'drop-down-button add-width' : 'drop-down-button'}
        style={multiStyle(isMulti)}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        aria-label={name}
      >
        { isMulti && !isSelectedMulti.includes(true) && (
        <div className="select-single">
          <p>{placeholder || t('all')}</p>
        </div>
        )}
        { isMulti && isSelectedMulti.length > 0 && !isSelectedMulti.includes(false) && (
        <div className="select-single">
          <p>{t('all')}</p>
        </div>
        )}
        {!isMulti && (
        <div className="select-single">
          <p>{isSelected}</p>
        </div>
        )}
        <IoMdArrowDropdown />
      </button>
    </div>
  );
};
MultiSelectDisplay.defaultProps = {
  options: [],
  setIsSelected: () => { },
  isSelectedMulti: [],
  isSelected: '',
  setIsOpen: () => { },
  name: '',
  isMulti: false,
  placeholder: '',
};
MultiSelectDisplay.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  setIsSelected: PropTypes.func,
  isSelected: PropTypes.string,
  isSelectedMulti: PropTypes.arrayOf(PropTypes.bool),
  setIsOpen: PropTypes.func,
  name: PropTypes.string,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default MultiSelectDisplay;
