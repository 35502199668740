import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/ToggleSwitch.scss';

const ToggleSwitch = ({
  name, value, onChange, id,
}) => (
  <label className="toggle-switch" htmlFor={id}>
    <input type="checkbox" name={name} checked={value} id={id} onClick={(e) => onChange(e.target.value)} onChange={() => {}} />
    <span className="slider" />
  </label>
);

ToggleSwitch.defaultProps = {
  name: '',
  value: false,
  onChange: () => {},
};
ToggleSwitch.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default ToggleSwitch;
