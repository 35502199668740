import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../styles/components/pages/ErrorPage.scss';

const UnauthorizedPage = () => {
  const { t } = useTranslation();
  return (
    <div className="error-page">
      <h1>{t('unauthorizedAccess')}</h1>
      <h2>
        {t('unauthorizedAccessDetails')}
        {' '}
        <a href="mailto:business_continuity@cooperators.ca" className="no-text-decoration">business_continuity@cooperators.ca</a>
      </h2>
      <Link to="/">{t('clickToGoHome')}</Link>
    </div>
  );
};

export default UnauthorizedPage;
