import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MdDragIndicator } from 'react-icons/md';
import { PiTrashLight } from 'react-icons/pi';

import '../styles/components/Row.scss';

/** Row component */
// eslint-disable-next-line react/display-name
const Row = React.forwardRef(({
  content, deletable, onDelete, dnd, draggable, deleteTitle, dragTitle,
}, ref) => {
  const { t } = useTranslation('common');

  return (
    <div ref={ref} className="row">
      {/* Need to pass the drag and drop props to the DOM node for drag and drop to work */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div className={`row-title${(dnd || draggable) ? ' row-draggable' : ''}`} {...dnd}>
        {(dnd || draggable) && <MdDragIndicator title={t(dragTitle)} />}
        {content}
      </div>
      {deletable && (
        <button type="button" onClick={onDelete}>
          <PiTrashLight title={t(deleteTitle)} />
        </button>
      )}
    </div>
  );
});

Row.defaultProps = {
  deletable: false,
  onDelete: () => {},
  dnd: undefined,
  draggable: false,
  deleteTitle: 'removeRow',
  dragTitle: 'moveRow',
};

Row.propTypes = {
  content: PropTypes.node.isRequired,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  dnd: PropTypes.shape({}),
  draggable: PropTypes.bool,
  deleteTitle: PropTypes.string,
  dragTitle: PropTypes.string,
};

export default Row;
