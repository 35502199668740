import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import DraggableRow from './DraggableRow';
import Row from './Row';

const SortableList = ({
  items,
  deletable,
  updateItems,
  accessability,
  deleteTitle,
  className,
  dragTitle,
}) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const { t } = useTranslation('common');
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const getItem = (id) => items.find((item) => item.id === id);
  const getIndex = (id) => items.findIndex((item) => item.id === id);

  const handleDragStart = ({ active }) => {
    setDraggedItem(getItem(active.id));
  };

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = getIndex(active.id);
      const newIndex = getIndex(over.id);
      updateItems(arrayMove(items, oldIndex, newIndex));
    }
    setDraggedItem(null);
  };

  const handleDelete = (index) => {
    updateItems(items.filter((_, i) => i !== index));
  };

  const announcements = {
    onDragStart({ active }) {
      const item = getItem(active.id);
      return t(accessability?.announcements?.dragStart ?? 'sortable.dragStart', {
        item: item.accessibleContent || item.content,
        position: getIndex(active.id) + 1,
        totalItems: items.length,
      });
    },
    onDragOver({ active, over }) {
      const item = getItem(active.id);
      return t(accessability?.announcements?.dragOver ?? 'sortable.dragOver', {
        item: item.accessibleContent || item.content,
        position: getIndex(over.id) + 1,
        totalItems: items.length,
      });
    },
    onDragEnd({ active, over }) {
      const item = getItem(active.id);
      return t(accessability?.announcements?.dragEnd ?? 'sortable.dragEnd', {
        item: item.accessibleContent || item.content,
        position: getIndex(over.id) + 1,
        totalItems: items.length,
      });
    },
    onDragCancel({ active }) {
      const item = getItem(active.id);
      return t(accessability?.announcements?.dragCancel ?? 'sortable.dragCancel', {
        item: item.accessibleContent || item.content,
      });
    },
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      accessibility={{
        announcements,
        screenReaderInstructions: { draggable: t(accessability?.instructions ?? 'sortable.instructions') },
      }}
    >
      <SortableContext items={items.map((item) => item.id)} strategy={verticalListSortingStrategy}>
        <div className={className}>
          {items.map((item, i) => (
            <DraggableRow
              key={item.id}
              content={item.content}
              id={item.id}
              deletable={deletable}
              onDelete={() => handleDelete(i)}
              deleteTitle={deleteTitle}
              dragTitle={dragTitle}
            />
          ))}
        </div>
      </SortableContext>
      <DragOverlay>
        {draggedItem && (
          <Row
            content={draggedItem.content}
            deletable={deletable}
            deleteTitle={deleteTitle}
            draggable
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};

SortableList.defaultProps = {
  items: [],
  deletable: false,
  deleteTitle: undefined,
  dragTitle: undefined,
  updateItems: () => {},
  accessability: undefined,
  className: undefined,
};

SortableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    accessibleContent: PropTypes.string,
  })),
  deletable: PropTypes.bool,
  deleteTitle: PropTypes.string,
  dragTitle: PropTypes.string,
  updateItems: PropTypes.func,
  accessability: PropTypes.shape({
    announcements: PropTypes.shape({
      dragStart: PropTypes.string,
      dragOver: PropTypes.string,
      dragEnd: PropTypes.string,
      dragCancel: PropTypes.string,
    }),
    instructions: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default SortableList;
