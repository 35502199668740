import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Card from '../Card';
import BlueButton from '../BlueButton';
import UserContext from '../../contexts/UserContext';
import { getActiveEvents, getCurrentEvents } from '../../hooks/EventHooks';
import PageAccessContext from '../../contexts/PageAccessContext';
import TwoButtonModal from '../TwoButtonModal';
import axios from '../../services/Api';
import teamsLogo from '../../assets/images/microsoftTeams.png';
import Role from '../../enums/Role';
import '../../styles/components/pages/CurrentEventsPage.scss';
import RecipientsModal from '../RecipientsModal';

/**
 * Current Events Page
 * @returns JSX component
 */
const CurrentEventsPage = () => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventId, setEventId] = useState(0);
  const { pageAccess } = useContext(PageAccessContext);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentEvent, setEvent] = useState({});
  const [alertId, setAlertId] = useState('');
  const [isRecipientModalOpen, setRecipientModalOpen] = useState(false);
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const access = pageAccess && pageAccess.StartEventPage === true;
  const eventAcess = user?.isAdmin
    || user?.role
      .some((role) => role.name.toLowerCase() === Role
        .CGLBusinessContinuityCoordinator.toLowerCase()
    || role.name.toLowerCase() === Role.BusinessContinuityCoordinator.toLowerCase());
  const viewRecipientsAccess = eventAcess
    || user?.role.some((role) => role.name.toLowerCase() === Role.OfficeLead.toLowerCase()
    || role.name.toLowerCase() === Role.LogisticsTeam.toLowerCase());

  const sortByDate = (a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp);
  const sortByLm = (a, b) => Date.parse(b.lastModified) - Date.parse(a.lastModified);

  /**
   * Closes the event given event Id
   */
  const closeEvent = async () => {
    const abortController = new AbortController();
    try {
      await axios.get(`/event/${eventId}/close`, { signal: abortController.signal });
      toast(t('eventClosed'), {
        type: 'success',
      });
    } catch (error) {
      if (error.name === 'CanceledError') return;
      toast(t('eventsCloseError'), {
        type: 'error',
      });
    }
    setCurrentEvents(currentEvents.filter((event) => event._id.toString() !== eventId));
    setIsModalOpen(false);
  };

  /**
   * Saves user reply to question
   * @param {*} status - status of the user
   * @returns
   */
  const userCheckIn = async (status) => {
    let updatedEvent;
    setDisableButton(true);
    try {
      const { data } = await toast.promise(axios.post(`/current-events/${eventId}/${alertId}/check-in`, { status }),
        {
          success: t('savedReply'),
          pending: t('savingReply'),
          error: t('errorSavingReply'),
        });

      updatedEvent = JSON.parse(JSON.stringify(data));
    } catch (error) {
      /** errors caught in toast */
    }
    setCurrentEvents(currentEvents.map((event) => {
      if (event._id === eventId) {
        return updatedEvent;
      }
      return event;
    }));
    setIsReplyModalOpen(false);
    setDisableButton(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    /**
     * Gets current events
     * @returns current events sorted
     */
    const getEvents = async () => {
      try {
        const data = access
          ? await getActiveEvents(abortController.signal)
          : await getCurrentEvents(abortController.signal);

        if (data && data.length > 0) {
          const sortedEvents = data.sort(sortByLm).map((ev) => ({
            ...ev,
            alerts: ev.alerts.sort(sortByDate).map((al) => {
              const date = new Date(al.timestamp);
              const newDate = date.toLocaleDateString(i18n.language, {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              });
              return {
                ...al,
                timestamp: newDate,
              };
            }),
          }));
          setCurrentEvents(sortedEvents);
        }
        setLoading(false);
      } catch (error) {
        if (error.name === 'CanceledError') return;
        toast(t('eventsError'), {
          type: 'error',
        });
      }
    };

    getEvents();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div>
      {loading
        && (
        <div className="text-container">
          <h2 className="large-text">{t('loading')}</h2>
        </div>
        )}
      { !loading && currentEvents.length > 0 ? currentEvents.map((event) => (
        <Card className="event-container" key={`event-${event._id}`}>
          <div className="event-card">
            <h2 className="event-header">{event.summary}</h2>
            <div className="card-header">{t('updates')}</div>

            {event.alerts.map((alert) => (
              <div className="event-update" key={`alert-${alert._id}`}>
                <p className="event-date">{alert.timestamp}</p>
                <p className="lighter">{alert?.payload?.data?.status}</p>
                <p className="lighter">{alert?.payload?.data?.nextSteps}</p>
                {alert?.payload?.data?.webex
                    && (
                    <div className="event-meeting">
                      <p className="lighter">{t('teamsMeeting')}</p>
                      <img id="teamsLogo" src={teamsLogo} className="teams-logo" alt={t('teamsLogo')} />

                    </div>
                    )}
                {/** recipients will be non existent property for some users without access */}
                <div className="event-recipients-div">
                  {viewRecipientsAccess && (
                  <button className="transparent-button" type="button" onClick={() => { setEvent({ ...event }); setAlertId(alert._id); setRecipientModalOpen(true); }}>
                    <p className="event-recipient">{t('viewRecipients')}</p>
                  </button>
                  )}
                  {access
                    /**
                     * Calling active events gets all events
                     * we have to show the reply button to relative user and event combo
                     * given the recipient list
                     */
                    ? alert?.recipients?.length > 0
                      && alert?.recipients?.map((al) => al.id).includes(user._id)
                    && (
                    <BlueButton
                      onClick={() => {
                        setEventId(event._id);
                        setAlertId(alert._id);
                        setIsReplyModalOpen(true);
                      }}
                      text={t('reply')}
                      disabled={disableButton}
                    />
                    )
                    /**
                     *  Calling current events endpoint
                     * will provide only relative events that the user can reply to
                     * so the reply button needs no condition here
                     */
                    : (
                      <BlueButton
                        onClick={() => {
                          setEventId(event._id);
                          setAlertId(alert._id);
                          setIsReplyModalOpen(true);
                        }}
                        text={t('reply')}
                        disabled={disableButton}
                      />
                    )}
                </div>
              </div>
            ))}
            {<div className="card-header">{t('affectedLocations')}</div>}
            {event.locations.length >= 1 ? event.locations.map((loc) => <p key={`${alert._id}-${loc}`} className="event-update lighter">{loc}</p>) : <p className="event-update">{t('noLocationsFound')}</p>}
            {/** Only Admin, BCC, and CGL BCC can view these buttons */}
            { eventAcess && (
              <div className="event-buttons">
                <Link style={{ textDecoration: 'none' }} to={`/update-event/${event._id}`} state={event}>
                  <BlueButton text={t('addUpdate')} />
                </Link>
                <BlueButton onClick={() => { setIsModalOpen(true); setEventId(event._id); }} text={t('endEvent')} />
              </div>
            )}
          </div>
        </Card>
      )) : (
        <div className="text-container">
          <h2 className="large-text">{t('noEventsFound')}</h2>
        </div>
      )}
      <TwoButtonModal
        isOpen={isModalOpen}
        redButtonFunctionality={() => closeEvent()}
        blueButtonFunctionality={() => setIsModalOpen(false)}
        closeFunctionality={() => setIsModalOpen(false)}
        headerText={t('endEventConfirmation')}
        redButtonText={t('yes')}
        blueButtonText={t('no')}
      />
      <TwoButtonModal
        isOpen={isReplyModalOpen}
        redButtonFunctionality={() => { userCheckIn('NOT_SAFE'); }}
        blueButtonFunctionality={() => { userCheckIn('SAFE'); }}
        closeFunctionality={() => setIsReplyModalOpen(false)}
        headerText={t('areYouSafe')}
        redButtonText={t('no')}
        blueButtonText={t('yes')}
      />
      {currentEvent._id && (
        <RecipientsModal
          isOpen={isRecipientModalOpen}
          event={currentEvent}
          closeFunctionality={() => { setRecipientModalOpen(false); setAlertId(''); }}
          alertId={alertId}
        />
      )}
    </div>
  );
};

export default CurrentEventsPage;
