import React, { useContext } from 'react';
import {
  createBrowserRouter, RouterProvider,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import PageContainer from '../components/PageContainer';
import HomePage from '../components/pages/HomePage';
import ProfilePage from '../components/pages/ProfilePage';
import muiTheme from '../muiTheme';
import AutoLogout from '../services/AutoLogout';
import UserContext from '../contexts/UserContext';
import MyTeamsPage from '../components/pages/MyTeamsPage';
import LifeWorksPage from '../components/pages/LifeWorksPage';
import SettingsPage from '../components/pages/SettingsPage';
import PageNotFound from '../components/pages/PageNotFound';
import UnauthorizedPage from '../components/pages/UnauthorizedPage';
import PageAccessProvider from '../providers/PageAccessProvider';
import ProtectedRoute from '../components/ProtectedRoute';
import BccQuickReferencePage from '../components/pages/BccQuickReferencePage';
import CommandCentersPage from '../components/pages/CommandCentersPage';
import CurrentEventsPage from '../components/pages/CurrentEventsPage';
import EmergencyProceduresPage from '../components/pages/EmergencyProceduresPage';
import DirectoryPage from '../components/pages/DirectoryPage';
import IncidentsManagementTeamsPage from '../components/pages/IncidentsManagementTeamsPage';
import ExternalSupportPage from '../components/pages/ExternalSupportPage';
import StartEventPage from '../components/pages/StartEventPage';
import DecisionMakersPage from '../components/pages/admin/DecisionMakersPage';

/** App Router component */
const AppRouter = () => {
  const { user } = useContext(UserContext);

  const routes = [
    { path: '', element: <HomePage /> },
    { path: 'profile', element: <ProtectedRoute link="/profile"><ProfilePage /></ProtectedRoute> },
    { path: 'settings', element: <ProtectedRoute link="/profile"><SettingsPage /></ProtectedRoute> },
    { path: 'bcc-quick-reference', element: <ProtectedRoute link="/bcc-quick-reference"><BccQuickReferencePage /></ProtectedRoute> },
    { path: 'my-teams', element: <ProtectedRoute link="/my-teams"><MyTeamsPage /></ProtectedRoute> },
    { path: 'life-works', element: <ProtectedRoute link="/life-works"><LifeWorksPage /></ProtectedRoute> },
    { path: 'command-centers', element: <ProtectedRoute link="command-centers"><CommandCentersPage /></ProtectedRoute> },
    { path: 'events', element: <ProtectedRoute link="/events"><CurrentEventsPage /></ProtectedRoute> },
    { path: 'emergency-procedures', element: <ProtectedRoute link="emergency-procedures"><EmergencyProceduresPage /></ProtectedRoute> },
    { path: 'settings', elements: <ProtectedRoute link="settings"><SettingsPage /></ProtectedRoute> },
    { path: 'directory', element: <ProtectedRoute link="directory"><DirectoryPage /></ProtectedRoute> },
    { path: 'external-support', element: <ProtectedRoute link="external-support"><ExternalSupportPage /></ProtectedRoute> },
    { path: 'start-event', element: <ProtectedRoute link="start-event"><StartEventPage /></ProtectedRoute> },
    { path: 'incidents-management-teams', element: <ProtectedRoute link="incidents-management-teams"><IncidentsManagementTeamsPage /></ProtectedRoute> },
    { path: 'update-event/:id', element: <ProtectedRoute link="update-event/:id"><StartEventPage addUpdate /></ProtectedRoute> },
    { path: 'unauthorized', element: <UnauthorizedPage /> },
    { path: '*', element: <PageNotFound /> },
  ];

  const adminRoutes = [
    { path: 'admin/user-database', element: <DirectoryPage admin /> },
    { path: 'admin/decision-makers', element: <DecisionMakersPage /> },
  ];

  if (user?.isAdmin) {
    routes.splice(routes.length, 0, ...adminRoutes);
  }

  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <PageContainer loggedIn />,
        children: routes,
      },
    ],
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <PageAccessProvider>
        <AutoLogout>
          <RouterProvider router={router} />
        </AutoLogout>
      </PageAccessProvider>
    </ThemeProvider>
  );
};

export default AppRouter;
